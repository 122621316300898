import React from 'react';
import { Provider } from 'react-redux';
import { useHistory } from 'react-router-dom'; 
import store from './js/store';
import Routes from './js/routes';

export default function App() {
    let history = useHistory();

    return (
        <Provider store={store}>
            <Routes
                history={history}
            />
        </Provider>
    );
}
