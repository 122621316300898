import React, { useState, useEffect } from 'react';
import { verifyRegister, verifyForget } from '../services/authService';

var timerID;

const VerifyButton = ({ handleSubmit, children, config}) => {
    const [ loader, setLoader ] = useState(false);
    const [ disable, setDisable ] = useState(false);
    const [ start, setStart ] = useState(false);
    const [ seconds, setSeconds ] = useState(120);

    useEffect(() => {
        setDisable(config.disable);
        setLoader(config.loader);
        setStart(config.start);
        setSeconds(config.seconds);
    }, [config])

    useEffect(()=> {
        if(! start) return;

        timerID = setInterval(() => {
            setSeconds(seconds => seconds - 1);
        }, 1000);

        return () => clearInterval(timerID);
    }, [start]);

    useEffect(() => {
        if(seconds === 0) {
            reset();
            clearInterval(timerID);
		}
    }, [seconds]);

    const reset = () => {
        setLoader(false);
        setDisable(false);
        setStart(false);
        setSeconds(120);
    }

    return (
        <button className="email_auth_btn" type="button" onClick={handleSubmit} disabled={disable}>
            { loader && (<div className="spinner-border spinner-border-sm mr-1"><span className="sr-only">Loading...</span></div>) }
            { !loader && !start && children }
            { start && <span style={{color: "#aaa"}}>重新發送 {seconds} s</span> }
        </button>
    )
}

export default VerifyButton;