import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

export default function Page(props) {
    
	return (
        <div className="start-page">
            <div className="start-wrapper">
                <div className="logo-img"></div>
                <div className="mgt-2 font-weight-bold" style={{fontSize: "28px", color: "#F2C94C"}}>Welcome</div>
                <div className="title">財神理財通</div>
                <div className="d-flex justify-content-center font-weight-bold" style={{fontSize: "24px"}}>積沙成塔<i className="common-icon icon-lg yuanbao mgx-2"></i>財氣亨通</div>
            </div>
            <div className="pdb-6 w-100">
                <Link to="/login" className="start-btn login">登入</Link>
                <Link to="/register" className="start-btn register">註冊帳號</Link>
            </div>
        </div>
	)
}