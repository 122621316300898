import React, { useContext, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { GlobalContext } from '../store/context/GlobalContext';

const PublicRoute = ({component: Component, isAuthenticated, ...rest}) => {
    const context = useContext(GlobalContext);
    const { setLoader, setSnack, hasHeader, hasFooter, mainTitle, subTitle, background } = rest;
    const initialProps = { setLoader, setSnack };
    
    useEffect(() => {
        context.setHeader(hasHeader);
        context.setMainTitle(mainTitle);
        context.setSubTitle(subTitle);
        context.setFooter(hasFooter);
        context.setBackground(background);
        window.scrollTo(0, 0);
    }, []);
    
    return (
        // <Route {...rest} render={props => (
        //     <Component {...props} { ...initialProps } />
        // )} />
        <Route {...rest} render={props => (
            !isAuthenticated ? (
                <Component {...props} { ...initialProps } />
            ) : (
                <Redirect to={{
                    pathname: '/dashboard',
                    state: { from: props.location },
                }}/>
            )
        )} />
    )
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated : state.Auth.isAuthenticated,
    }
};

export default connect(mapStateToProps)(PublicRoute);