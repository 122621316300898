import React, { useState, useEffect, useCallback } from 'react';
import useForm from "react-hook-form";
import VerifyButton from '../../../../common/VerifyButton';
import { forgotEmailVerify, forgotPhoneVerify, forgotVerify } from '../../../../services/authService';
import Header from '../../../../common/Header';

const Step2 = ({ setForm, formData, navigation, setSnack, setLoader, ...props }) => {
    const { previous, next } = navigation;
    const { register, handleSubmit } = useForm();
    const [ verifyButtonConfig, setVerifyButtonConfig ] = useState({ disable: true, loader: false, start: true, seconds: 120 });
    
    const handleGetVerify = useCallback(() => {
        // setVerifyButtonConfig({...verifyButtonConfig, disable: true, loader: true, start: false});
        if(formData.mode === "phone"){
            forgotPhoneVerify({phone_code: formData.phone_code, phone: formData.phone}).then(res => {
                setVerifyButtonConfig({...verifyButtonConfig, disable: true, loader: false, start: true});
                setSnack({open: true, text: res.messages});
            }).catch(err => {
                setVerifyButtonConfig({...verifyButtonConfig, disable: false, loader: false, start: false, seconds: 120});
                setSnack({open: true, text: err.messages.error});
            })
        }else{
            forgotEmailVerify({email: formData.email}).then(res => {
                setVerifyButtonConfig({...verifyButtonConfig, disable: true, loader: false, start: true});
                setSnack({open: true, text: res.messages});
            }).catch(err => {
                setVerifyButtonConfig({...verifyButtonConfig, disable: false, loader: false, start: false, seconds: 120});
                setSnack({open: true, text: err.messages.error});
            })
        }
    }, [])

    const handleStep2 = () => {
        let credentials = {};
        if(formData.verify === ""){
            setSnack({open: true, text: "請輸入驗證碼"});
            return;
        }

        if(formData.mode === "phone"){
            credentials = {phone: formData.phone, phone_code: formData.phone_code, verify: formData.verify};
        }
        if(formData.mode === "email"){
            credentials = {email: formData.email, verify: formData.verify};
        }

        setLoader(true);
        forgotVerify(credentials).then(res => {
            setLoader(false);
            next();
        }).catch(err => {
            setLoader(false);
            setSnack({open: true, text: err.messages.error});
        })
    }

    const inputNumber = e => {
        let code = e.keyCode;
        if((code < 48 || code > 57) && code !== 190 && code !== 8 && code !== 9 && (code < 96 || code > 105) ) {
            e.preventDefault();
        }
    }

    return (<>
        <Header title="安全驗證" scroll back={() => props.history.replace("/login")} /> 
        <div className="flex-fill">
            <div className="common-title">安全驗證</div>
            <div className="pd-3">
                <div className="form-group">
                    <div className="label-group">
                        <label>驗證碼</label>
                        {
                            formData.mode === "phone" ? (
                                <div
                                    className="font-weight-bold text-main"
                                    style={{cursor: 'pointer'}}
                                    onClick={() => {
                                        previous();
                                        setForm({
                                            target: {
                                                name: 'mode',
                                                value: "phone"
                                            }
                                        })
                                    }}
                                >
                                    重新輸入手機號碼
                                </div>
                            ) : (
                                <div
                                    className="font-weight-bold text-main"
                                    style={{cursor: 'pointer'}}
                                    onClick={() => {
                                        previous();
                                        setForm({
                                            target: {
                                                name: 'mode',
                                                value: "email"
                                            }
                                        })
                                    }}
                                >
                                    重新輸入郵箱地址
                                </div>
                            )
                        }
                    </div>
                    <div className="input-group">
                        <input
                            tabIndex="1"
                            name="verify"
                            style={{paddingRight: "115px"}}
                            pattern="[0-9]*"
                            inputMode="numeric"
                            placeholder="輸入驗證碼"
                            autoComplete="off"
                            spellCheck="false"
                            ref={register({required: true})}
                            onChange={(e) => setForm({
                                target: {
                                    name: 'verify',
                                    value: e.target.value
                                }
                            })}
                            onKeyDown={(e) => inputNumber(e)}
                        />
                        <div className="input-desc">
                            <VerifyButton handleSubmit={handleGetVerify} config={verifyButtonConfig}>寄送驗證碼</VerifyButton>
                        </div>
                    </div>
                    <div className="mgt-2 text-secondary">驗證碼已發送到您的{formData.mode === "phone" ? <>行動裝置 <div className="d-inline-block">{formData.phone_code} {formData.phone}</div></> : `信箱 ${formData.email}`}</div>
                </div>
            </div>
        </div>
        <div className="pdx-3 pdy-8">
            <button type="button" className="submit-btn" onClick={() => handleStep2()}>提交</button>
        </div>
    </>)
}

export default Step2;