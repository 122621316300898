import React, { useState, useEffect } from 'react';
import useForm from "react-hook-form";
import { FormGroup, InputGroup } from '../../../common/FormGroup';
import Header from '../../../common/Header';

export default function Page() {
    const { register, handleSubmit, setValue, getValues } = useForm();
    const [ amount, setAmount ] = useState(0);

    const onSubmit = (data) => {

    }

    const handleSetAmount = value => {
        if(value < 0) {
            setAmount(0);
        }else{
            setAmount(value);
        }
        if(value.charAt(0) === 0){
            value = value.replace(/\b(0+)/gi,"");
            setAmount(value);
        }
    }

    const handleAmountCheck = value => {
        let amountInput = document.getElementById('amountInput');
        if(value.length > 1){
            if(value.charAt(0) === "0"){
                value = value.replace(/\b(0+)/gi,"");
                amountInput.value = Math.round(amountInput.value*10000)/10000;
            }
        }
    }

    const inputNumber = e => {
        let code = e.keyCode;
        if((code < 48 || code > 57) && code !== 190 && code !== 8 && code !== 9 && (code < 96 || code > 105) ) {
            e.preventDefault();
        }
    }

    return (<>
        <Header title="獎金轉點數" scroll />
        <form className="common-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="flex-fill">
                <div className="common-title">獎金轉點數</div>
                <div className="form-container pd-3">
                    <FormGroup label="轉換類型">
                        <InputGroup>
                            <select
                                name="type"
                                defaultValue="1"
                            >
                                <option value="1">錢包轉點數</option>
                            </select>
                        </InputGroup>
                    </FormGroup>
                    <FormGroup label={`轉換數量 ( 錢包餘額 $13,864 )`}>
                        <InputGroup>
                            <input
                                id="amountInput"
                                tabIndex="1"
                                name="amount"
                                type="text"
                                pattern="[0-9]*"
                                inputMode="numeric"
                                placeholder="轉換數量"
                                autoComplete="off"
                                spellCheck="false"
                                ref={register}
                                onChange={(e) => handleSetAmount(e.target.value)}
                                onBlur={(e) => handleAmountCheck(e.target.value)}
                                onKeyDown={(e) => inputNumber(e)}
                            />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup label="兌換後點數 ( 兌換比例 1 : 1 )">
                        <InputGroup>
                            <input
                                type="text"
                                value={`${amount ? Math.round(amount *10000)/10000 : 0} 點`}
                                readOnly
                            />
                        </InputGroup>
                    </FormGroup>
                    <div className="form-desc mgt-6">
                        <div className="title">溫馨提醒</div>
                        <div>確認轉換後，無法要求換回獎金或現金</div>
                    </div>
                </div>
            </div>
            <div className="pdx-3 pdy-8">
                <button type="submit" className="submit-btn">確定轉換</button>
            </div>
        </form>
    </>)
}