//Home-----------------------------------------------------
import Start from '../modules/Auth/Start';
import Login from '../modules/Auth/Login';
import Forget from '../modules/Auth/Forget';
// import Register from '../modules/Auth/Register';
// import Register from '../modules/Auth/Register';

// Home-----------------------------------------------------
import UserHome from '../modules/Home';
import Notification from '../modules/Home/Notification';
import NotificationItem from '../modules/Home/Notification/NotificationItem';

// News-----------------------------------------------------
import News from '../modules/News';

// Quantify-----------------------------------------------------
import Quantify from '../modules/Quantify';
import QuantifyCreate from '../modules/Quantify/Create';
import QuantifyDetail from '../modules/Quantify/Detail';

// Bid-----------------------------------------------------
import Bid from '../modules/Bid';

//Dashboard-----------------------------------------------------
import Dashboard from '../modules/Dashboard';
import DashboardUpgrade from '../modules/Dashboard/Upgrade';
import DashboardWallet from '../modules/Dashboard/Wallet';
import DashboardTransfer from '../modules/Dashboard/Transfer';
import DashboardTeam from '../modules/Dashboard/Team';
import DashboardInvite from '../modules/Dashboard/Invite';
import DashboardPersonal from '../modules/Dashboard/Personal';
import DashboardPassword from '../modules/Dashboard/Password';
import DashboardEmail from '../modules/Dashboard/Email';
import DashboardIdentity from '../modules/Dashboard/Identity';
import DashboardBank from '../modules/Dashboard/Bank';
import DashboardERC20 from '../modules/Dashboard/ERC20';
import DashboardUserGuide from '../modules/Dashboard/UserGuide';
import DashboardAbout from '../modules/Dashboard/About';
import DashboardConfig from '../modules/Dashboard/Config';
import { lazy } from 'react';

// const Dashboard = lazy(() => import("../modules/Dashboard"));

const routes = [
    {
        path: '/',
        exact: true,
        component: Login,
        hasHeader: false,
        hasFooter: false,
        background: "login",
        // mainTitle: '首頁',
    },
    // {
    //     path: '/',
    //     exact: true,
    //     component: UserHome,
    //     hasHeader: false,
    //     hasFooter: true,
    //     mainTitle: '首頁',
    // },
    {
        path: '/login',
        auth: false,
        component: Login,
        hasHeader: false,
        hasFooter: false,
        subTitle: "",
        background: "login",
    },
    // {
    //     path: '/register',
    //     exact: true,
    //     auth: false,
    //     component: Register,
    //     hasHeader: false,
    //     hasFooter: false,
    //     subTitle: "註冊帳號",
    //     background: "white",
    // },
    // {
    //     path: '/register/:code',
    //     auth: false,
    //     component: Register,
    //     hasHeader: false,
    //     hasFooter: false,
    //     subTitle: "註冊帳號",
    //     background: "white",
    // },
    {
        path: '/forget',
        auth: false,
        component: Forget,
        subTitle: "",
        hasHeader: false,
        hasFooter: false,
        background: "white",
    },
    // {
    //     path: '/register',
    //     exact: true,
    //     auth: false,
    //     component: Register,
    //     hasHeader: true,
    //     subTitle: '註冊'
    // },
    // {
    //     path: '/register/:code',
    //     auth: false,
    //     component: Register,
    //     hasHeader: true,
    //     subTitle: '註冊'
    // },
    // {
    //     path: '/forget',
    //     auth: false,
    //     component: Forget,
    //     hasHeader: true,
    //     subTitle: '忘記密碼'
    // },
    // {
    //     path: '/home',
    //     exact: true,
    //     auth: true,
    //     component: UserHome,
    //     hasHeader: false,
    //     hasFooter: true,
    //     mainTitle: '首頁',
    // },
    // {
    //     path: '/notification',
    //     exact: true,
    //     auth: true,
    //     component: Notification,
    //     hasHeader: false,
    //     hasFooter: false,
    //     subTitle: '通知中心',
    //     background: "white",
    // },
    // {
    //     path: '/notification/:id',
    //     exact: true,
    //     auth: true,
    //     component: NotificationItem,
    //     hasHeader: true,
    //     hasFooter: false,
    //     subTitle: '查看通知',
    //     background: "white",
    // },
    // {
    //     path: '/news',
    //     exact: true,
    //     auth: true,
    //     component: News,
    //     mainTitle: '消息',
    //     hasHeader: false,
    //     hasFooter: true,
    // },
    // {
    //     path: '/quantify',
    //     exact: true,
    //     auth: true,
    //     component: Quantify,
    //     hasHeader: false,
    //     hasFooter: true,
    //     mainTitle: '量化',
    // },
    // {
    //     path: '/quantify/create',
    //     exact: true,
    //     auth: true,
    //     component: QuantifyCreate,
    //     hasHeader: true,
    //     hasFooter: false,
    //     subTitle: '量化新增',
    // },
    // {
    //     path: '/quantify/detail/:id',
    //     exact: true,
    //     auth: true,
    //     component: QuantifyDetail,
    //     hasHeader: true,
    //     hasFooter: false,
    //     subTitle: '量化資訊',
    // },
    // {
    //     path: '/bid',
    //     exact: true,
    //     auth: true,
    //     component: Bid,
    //     hasHeader: false,
    //     hasFooter: true,
    //     mainTitle: '標會',
    // },
    {
        path: '/dashboard',
        exact: true,
        auth: true,
        component: Dashboard,
        hasHeader: false,
        hasFooter: false,
        mainTitle: '我的',
        background: "white",
    },
    // {
    //     path: '/dashboard/upgrade',
    //     exact: true,
    //     auth: true,
    //     component: DashboardUpgrade,
    //     hasHeader: true,
    //     hasFooter: false,
    //     subTitle: '升級 SX 會員',
    // },
    // {
    //     path: '/dashboard/wallet',
    //     exact: true,
    //     auth: true,
    //     component: DashboardWallet,
    //     hasHeader: true,
    //     hasFooter: false,
    //     subTitle: '錢包資產',
    // },
    // {
    //     path: '/dashboard/transfer',
    //     exact: true,
    //     auth: true,
    //     component: DashboardTransfer,
    //     hasHeader: false,
    //     hasFooter: false,
    //     subTitle: '獎金轉點數',
    //     background: "white",
    // },
    // {
    //     path: '/dashboard/team',
    //     exact: true,
    //     auth: true,
    //     component: DashboardTeam,
    //     hasHeader: true,
    //     hasFooter: false,
    //     subTitle: '好友',
    //     background: "white",
    // },
    // {
    //     path: '/dashboard/invite',
    //     exact: true,
    //     auth: true,
    //     component: DashboardInvite,
    //     hasHeader: false,
    //     hasFooter: false,
    //     subTitle: '邀請好友',
    //     background: "white",
    // },
    {
        path: '/dashboard/personal',
        exact: true,
        auth: true,
        component: DashboardPersonal,
        hasHeader: false,
        hasFooter: false,
        subTitle: '修改個人資料',
        background: "white",
    },
    {
        path: '/dashboard/password',
        exact: true,
        auth: true,
        component: DashboardPassword,
        hasHeader: false,
        hasFooter: false,
        subTitle: '修改密碼',
        background: "white",
    },
    // {
    //     path: '/dashboard/email',
    //     exact: true,
    //     auth: true,
    //     component: DashboardEmail,
    //     hasHeader: false,
    //     hasFooter: false,
    //     subTitle: '信箱',
    //     background: "white",
    // },
    {
        path: '/dashboard/identity',
        exact: true,
        auth: true,
        component: DashboardIdentity,
        hasHeader: false,
        hasFooter: false,
        subTitle: '身份驗證',
        background: "white",
    },
    {
        path: '/dashboard/bank',
        exact: true,
        auth: true,
        component: DashboardBank,
        hasHeader: false,
        hasFooter: false,
        subTitle: '銀行帳戶',
        background: "white",
    },
    //     {
    //     path: '/dashboard/erc20',
    //     exact: true,
    //     auth: true,
    //     component: DashboardERC20,
    //     hasHeader: false,
    //     hasFooter: false,
    //     subTitle: '電子錢包',
    //     background: "white",
    // },
    // {
    //     path: '/dashboard/user-guide',
    //     exact: true,
    //     auth: true,
    //     component: DashboardUserGuide,
    //     hasHeader: false,
    //     hasFooter: false,
    //     subTitle: '新手指南',
    //     background: "white",
    // },
    // {
    //     path: '/dashboard/about',
    //     exact: true,
    //     auth: true,
    //     component: DashboardAbout,
    //     hasHeader: true,
    //     hasFooter: false,
    //     subTitle: '關於我們',
    //     background: "white",
    // },
    // {
    //     path: '/dashboard/config',
    //     exact: true,
    //     auth: true,
    //     component: DashboardConfig,
    //     hasHeader: false,
    //     hasFooter: false,
    //     subTitle: '語系',
    //     background: "white",
    // },
];

export default routes;