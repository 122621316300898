import React, { useContext } from 'react';
import { GlobalContext } from '../store/context/GlobalContext';
import Footer from './Footer';
import Header from './Header';

export default function Private(props) {
    const { children } = props;
    const context = useContext(GlobalContext);

    return (<>
        <Header/>
		<main className={!context.header ? 'pt-0' : ''}>
			{ children }
		</main>
        <Footer/>
    </>)
}
