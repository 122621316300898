import React, { useState, useEffect, useCallback } from 'react';
import useForm from "react-hook-form";
import { updatePassword } from '../../../services/authService';
import { FormGroup, InputGroup } from '../../../common/FormGroup';
import Header from '../../../common/Header';

const BASE_URL = process.env.REACT_APP_BASE_URL;

export default function Page(props) {
    const { user, setSnack, setLoader } = props;
    const { register, handleSubmit, reset, setValue } = useForm();

    return (<>
        <Header title="邀請好友" scroll />
        <div className="common-form">
            <div className="flex-fill">
                <div className="common-title">邀請好友</div>
                <div className="form-container pd-3">
                    <FormGroup label="我的邀請碼">
                        <InputGroup>
                            <input
                                type="text"
                                defaultValue={user.invitation_token}
                                onFocus={() => document.activeElement.blur()}
                            />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup label="我的邀請連結">
                        <InputGroup>
                            <input
                                type="text"
                                value={`${BASE_URL}/${user.invitation_token}`}
                                onChange={() => {return;}}
                                onFocus={() => document.activeElement.blur()}
                            />
                        </InputGroup>
                    </FormGroup>
                </div>
            </div>
            {/* <div className="pdx-3 pdy-8">
                <button type="submit" className="submit-btn">送出</button>
            </div> */}
        </div>
    </>)
}