import React, { useState, useEffect, useCallback } from 'react';
import useForm from "react-hook-form";
import { emailVerify, emailVerifySubmit, fetchUser } from '../../../services/authService';
import { FormGroup, InputGroup } from '../../../common/FormGroup';
import VerifyButton from '../../../common/VerifyButton';
import Header from '../../../common/Header';

export default function Page(props) {
    const { user, setSnack, setLoader } = props;
    const { register, handleSubmit, reset } = useForm();
    const [ showTitle, setShowTitle ] = useState(false);
    const [ verifyData, setVerifyData ] = useState("");
    const [ verifyButtonConfig, setVerifyButtonConfig ] = useState({ disable: false, loader: false, start: false, seconds: 120 });

    const handleGetVerify = useCallback(() => {
        if(verifyData === ""){
            setSnack({open: true, text: "請先輸入信箱"});
            return;
        }
        setVerifyButtonConfig({...verifyButtonConfig, disable: true, loader: true, start: false});
        emailVerify({email: verifyData}).then(res => {
            setVerifyButtonConfig({...verifyButtonConfig, disable: true, loader: false, start: true});
            setSnack({open: true, text: res.messages});
        }).catch(err => {
            setVerifyButtonConfig({...verifyButtonConfig, disable: false, loader: false, start: false, seconds: 120});
            setSnack({open: true, text: err.messages.error});
        })
    }, [verifyData])

    const onSubmit = (data) => {
        if(data.email === ""){
            setSnack({open: true, text: "請填寫信箱"});
            return;
        }
        if(data.verify === ""){
            setSnack({open: true, text: "請填寫驗證碼"});
            return;
        }
        setLoader(true);
        emailVerifySubmit(data).then(res => {
            setLoader(false);
            props.dispatch(fetchUser());
            setSnack({open: true, text: res.messages});
        }).catch(err => {
            setLoader(false);
            setSnack({open: true, text: err.messages.error});
        })
    }

    const AuthStatus = (status) => {
        switch (status) {
            case 0: return(<div className="font-size-ss text-white badge bg-secondary mgl-2">尚未認證</div>);
            case 1: return(<div className="font-size-ss text-white badge bg-success mgl-2">認證成功</div>);
            default: return;
        }
    }

    return (<>
        <Header title="信箱認證" scroll />
        <form className="common-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="flex-fill">
                <div className="common-title">信箱認證  {AuthStatus(user.email_verified)}</div>
                <div className="form-container pd-3">
                    <FormGroup label="信箱">
                        <InputGroup>
                            {
                                user &&
                                user.email_verified === 0 ? (
                                    <input
                                        tabIndex="1"
                                        name="email"
                                        type="text"
                                        placeholder="郵箱地址"
                                        autoComplete="off"
                                        spellCheck="false"
                                        ref={register}
                                        onChange={(e) => setVerifyData(e.target.value)}
                                    />
                                ) : (
                                    <input
                                        type="text"
                                        defaultValue={user.email}
                                        readOnly
                                    />
                                )
                            }
                        </InputGroup>
                    </FormGroup>
                    {
                        user.email_verified !== 1 &&
                        <FormGroup label="驗證碼">
                            <InputGroup>
                                <input
                                    tabIndex="1"
                                    name="verify"
                                    type="text"
                                    placeholder="請輸入驗證碼"
                                    autoComplete="off"
                                    spellCheck="false"
                                    ref={register}
                                />
                                <div className="input-desc">
                                    <VerifyButton handleSubmit={handleGetVerify} config={verifyButtonConfig}>寄送驗證碼</VerifyButton>
                                </div>
                            </InputGroup>
                        </FormGroup>
                    }
                </div>
            </div>
            {
                user.email_verified !== 1 &&
                <div className="pdx-3 pdy-8">
                    <button type="submit" className="submit-btn">送出</button>
                </div>
            }
        </form>
    </>)
}
