import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { NavLink } from "react-router-dom";
import { GlobalContext } from '../store/context/GlobalContext';

export default function Footer() {
    const context = useContext(GlobalContext);
    const { footer } = context;

    const menu = [
        {
            link: '/home',
            title: '首頁',
            icon: 'home',
        },
        // {
        //     link: '/quantify',
        //     title: '量化交易',
        //     icon: 'quantify',
        // },
        {
            link: '/bid',
            title: '標會',
            icon: 'bid',
        },
        {
            link: '/news',
            title: '消息',
            icon: 'news',
        },
        {
            link: '/dashboard',
            title: '我的',
            icon: 'dashboard',
        },
    ];

    if(!footer) return <></>; 

    return (
        <footer className="user-footer">
            <ul className="footer-list">
                { menu.map((item, key) => (
                    <li className="footer-item" key={key}>
                        { item.link && (
                            <NavLink to={item.link} activeClassName="selected">
                                <i className={`footer-icon ${item.icon}`}></i> <div className={item.title === "量化交易" ? "center" : ""}>{item.title}</div>
                            </NavLink>
                        )}
                        { item.onclick && (
                            <div onClick={item.onclick}>
                                <i className={`footer-icon ${item.icon}`}></i>  <div>{item.title}</div>
                            </div>
                        )}
                    </li>
                ))}
            </ul>
        </footer>
    )
}