import { connect } from 'react-redux';
import Page from './Page';

const mapStateToProps = state => {
    return {
        isAuthenticated : state.Auth.isAuthenticated,
        isDeveloper: state.Auth.isDeveloper,
        user : state.Auth.user,
        locale : state.locales.intl.locale,
    }
}

export default connect(mapStateToProps)(Page);