import React, { useState, useEffect, useCallback } from 'react';
import Header from '../../../common/Header';

export default function Page(props) {
    let mounted = true;
    const { setSnack, setLoader } = props;

    return (
        <div style={{paddingTop: "60px"}}>
            <Header title="新手指南" scroll/>
            <div className="common-title">新手指南</div>
            <div className="pd-3">
                <div>新手指南內容</div>
                <div>新手指南內容</div>
                <div>新手指南內容</div>
                <div>新手指南內容</div>
                <div>新手指南內容</div>
                <div>新手指南內容</div>
                <div>新手指南內容</div>
                <div>新手指南內容</div>
                <div>新手指南內容</div>
                <div>新手指南內容</div>
                <div>新手指南內容</div>
                <div>新手指南內容</div>
                <div>新手指南內容</div>
                <div>新手指南內容</div>
                <div>新手指南內容</div>
                <div>新手指南內容</div>
                <div>新手指南內容</div>
                <div>新手指南內容</div>
                <div>新手指南內容</div>
                <div>新手指南內容</div>
                <div>新手指南內容</div>
                <div>新手指南內容</div>
                <div>新手指南內容</div>
                <div>新手指南內容</div>
                <div>新手指南內容</div>
                <div>新手指南內容</div>
                <div>新手指南內容</div>
                <div>新手指南內容</div>
                <div>新手指南內容</div>
                <div>新手指南內容</div>
                <div>新手指南內容</div>
                <div>新手指南內容</div>
                <div>新手指南內容</div>
                <div>新手指南內容</div>
                <div>新手指南內容</div>
                <div>新手指南內容</div>
            </div>
        </div>
    )
}
