import React, { useState } from 'react';

export default function Page() {
    const [ walletType, setWalletType ] = useState("1");

    const walletData = [
        {
            id: "1",
            type: "團隊",
            amount: "50.2241",
            note: "-",
            created_at: "2021/05/28 12:15:45",
        },
        {
            id: "2",
            type: "充值",
            amount: "-50.2241",
            note: "-",
            created_at: "2021/05/28 12:15:45",
        },
        {
            id: "3",
            type: "兌換",
            amount: "50.2241",
            note: "-",
            created_at: "2021/05/28 12:15:45",
        },
        {
            id: "4",
            type: "提領",
            amount: "50.2241",
            note: "-",
            created_at: "2021/05/28 12:15:45",
        },
        {
            id: "5",
            type: "開通",
            amount: "-100.0000",
            note: "-",
            created_at: "2021/05/28 12:15:45",
        },
    ]

    return (
        <div className="wallet-page">
            <div className="bg-white pdt-3 pdx-3 mgb-3">
                <div className="d-flex">
                    <ToolsBox title="分潤收益" icon="fal fa-gem fa-lg" color="#2EC4B6" >
                        <div className="content">123.7413</div>
                    </ToolsBox>
                    <ToolsBox title="錢包餘額" icon="fal fa-wallet fa-lg" color="#E63946" >
                        <div className="content">36,234.1234</div>
                    </ToolsBox>
                </div>
            </div>
            <div className="bg-white">
                <h5 className="font-weight-bold-xl pdt-3 pdx-3">錢包紀錄</h5>
                <div className="pdx-3">
                    <button type="button" className={`wallet-btn ${walletType === "1" ? "active" : ""}`} onClick={() => setWalletType("1")}>全部</button>
                    <button type="button" className={`wallet-btn ${walletType === "2" ? "active" : ""}`} onClick={() => setWalletType("2")}>團隊收益</button>
                    <button type="button" className={`wallet-btn ${walletType === "3" ? "active" : ""}`} onClick={() => setWalletType("3")}>充值紀錄</button>
                    <button type="button" className={`wallet-btn ${walletType === "4" ? "active" : ""}`} onClick={() => setWalletType("4")}>兌換紀錄</button>
                    <button type="button" className={`wallet-btn ${walletType === "5" ? "active" : ""}`} onClick={() => setWalletType("5")}>提領紀錄</button>
                    <button type="button" className={`wallet-btn ${walletType === "6" ? "active" : ""}`} onClick={() => setWalletType("6")}>開通紀錄</button>
                </div>
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">類型</th>
                                <th scope="col">金額</th>
                                <th scope="col">備註</th>
                                <th scope="col">時間</th>
                            </tr>
                        </thead>
                        <tbody>
                            { walletData.map((item, key) => <WalletItem item={item} key={key} />) }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

const ToolsBox = (props) => {
    const { icon, title, children, wrap, color } = props;
    
    return (
        <div className={`tools-box ${wrap ? 'd-flex justify-content-between align-items-center' : ''}`}>
            <div className="d-flex align-items-center justify-content-between">
                <div className="font-weight-bold font-size-md">{title}</div>
                <i className={`mgr-1 ${icon}`} style={{color: color}}></i>
            </div>
            <div className="d-flex align-items-center">
                { children }
            </div>
        </div>
    )
}

const WalletItem = (props) => {
    const { item } = props;

    return (
        <tr>
            <td>{item.type}</td>
            <td>{parseInt(item.amount) > 0 ? <span style={{color: "#11999E"}}>+{item.amount}</span> : <span style={{color: "#e63946"}}>{item.amount}</span>}</td>
            <td>{item.note}</td>
            <td>{item.created_at}</td>
        </tr>
    )
}