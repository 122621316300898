import React, { useState, useEffect, useCallback } from 'react';
import useForm from "react-hook-form";
import { getBank, bankVerify, fetchUser } from '../../../services/authService';
import { FormGroup, InputGroup } from '../../../common/FormGroup';
import bankList from "../../../common/TaiwanBankCode";
import Upload from '../../../common/Upload';
import Header from '../../../common/Header';

export default function Page(props) {
    let mounted = true;
    const { user, setSnack, setLoader } = props;
    const { register, handleSubmit, reset, setValue } = useForm();
    const [ bank, setBank ] = useState({
        bankname: "",
        bankbranch: "",
        bankaccount: "",
        bankid: "",
        image: "",
    })

    const fetchData = useCallback(async() => {
        setLoader(true);
        let result = await getBank();
        if(mounted){
            setBank(result.data.content);
            setLoader(false);
        }
    }, [mounted])
    
    useEffect(() => {
        fetchData();
        return () => mounted = false;
    }, [fetchData])

    const onSubmit = (data) => {
        if(data.bankname === ""){
            setSnack({open: true, text: "請選擇銀行"});
            return;
        }
        if(data.bankbranch === ""){
            setSnack({open: true, text: "請填寫分行名稱"});
            return;
        }
        if(data.bankaccount === ""){
            setSnack({open: true, text: "請填寫帳戶戶名"});
            return;
        }
        if(data.bankid === ""){
            setSnack({open: true, text: "請填寫銀行帳號"});
            return;
        }
        if(data.image.length === 0 && data.image === ""){
            setSnack({open: true, text: "請上傳銀行存摺"});
            return;
        }
        
        if(data.image.length === 0) delete data.image;
        
        const formData = new FormData();
		Object.keys(data).map(function(key, index) {
            return key==='image' ? formData.append(key, data.image[0]) : formData.append(key, data[key])
        });

        setLoader(true);
        bankVerify(formData).then(res => {
            setLoader(false);
            props.dispatch(fetchUser());
            setSnack({open: true, text: res.messages});
        }).catch(err => {
            setLoader(false);
            setSnack({open: true, text: err.messages.error});
        })
    }

    const AuthStatus = (status) => {
        switch (status) {
            case -1: return (<div className="font-size-ss text-white badge bg-danger mgl-2">審核失敗</div>);
            case 0: return(<div className="font-size-ss text-white badge bg-secondary mgl-2">尚未認證</div>);
            case 1: return(<div className="font-size-ss text-white badge bg-warning mgl-2">審核中</div>);
            case 2: return(<div className="font-size-ss text-white badge bg-success mgl-2">認證成功</div>);
            default: return;
        }
    }

    const inputNumber = (e) => {
        let code = e.keyCode;
        if((code < 48 || code > 57) && code !== 190 && code !== 8 && code !== 9 && (code < 96 || code > 105) ) {
            e.preventDefault();
        }
    }

    return (<>
        <Header title="銀行帳戶" scroll />
        <form className="common-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="flex-fill">
                <div className="common-title">銀行帳戶 {AuthStatus(user.bank_verified)}</div>
                <div className="form-container pd-3">
                    <FormGroup label="銀行名稱">
                        <InputGroup>
                            {
                                user.bank_verified === 2 ? (
                                    <input
                                        type="text"
                                        defaultValue={bank.bankname}
                                        readOnly
                                    />
                                ) : (
                                    <select
                                        name="bankname"
                                        value={bank.bankname}
                                        onChange={(e) => setBank({...bank, bankname: e.target.value})}
                                        ref={register}
                                    >
                                        <option value="" disabled>請選擇銀行</option>
                                        { bankList.map((item, key) => <option value={`${item.code} ${item.name}`} key={key}>{item.code} {item.name}</option>) }
                                    </select>
                                )
                            }
                        </InputGroup>
                    </FormGroup>
                    <FormGroup label="分行名稱">
                        <InputGroup>
                            <input
                                tabIndex="1"
                                name="bankbranch"
                                type="text"
                                placeholder={user.bank_verified !== 2 ? "請輸入分行名稱" : ""}
                                autoComplete="off"
                                spellCheck="false"
                                ref={register}
                                defaultValue={bank.bankbranch}
                                readOnly={user.bank_verified === 2}
                            />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup label="帳戶戶名">
                        <InputGroup>
                            <input
                                tabIndex="1"
                                name="bankaccount"
                                type="text"
                                placeholder={user.bank_verified !== 2 ? "請輸入帳戶戶名" : ""}
                                autoComplete="off"
                                spellCheck="false"
                                ref={register}
                                defaultValue={bank.bankaccount}
                                readOnly={user.bank_verified === 2}
                            />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup label="銀行帳號">
                        <InputGroup>
                            <input
                                tabIndex="1"
                                name="bankid"
                                type="text"
                                placeholder={user.bank_verified !== 2 ? "請輸入銀行帳號" : ""}
                                pattern="[0-9]*"
                                inputMode="numeric"
                                autoComplete="off"
                                spellCheck="false"
                                ref={register}
                                onKeyDown={(e) => inputNumber(e)}
                                defaultValue={bank.bankid}
                                readOnly={user.bank_verified === 2}
                            />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup label="上傳銀行存摺">
                        <Upload
                            id="image"
                            maxWidth="auto"
                            maxHeight="200px"
                            name="image"
                            valid={{type: ["image/jpeg", "image/png"]}}
                            register={register}
                            clearCallback={() => setValue("image", "")}
                            defaultValue={bank.image}
                            readOnly={user.bank_verified === 2}
                        />
                    </FormGroup>
                </div>
            </div>
            {
                user.bank_verified !== 2 &&
                <div className="pdx-3 pdy-8">
                    <button type="submit" className="submit-btn">{user.bank_verified === 0 ? "送出" : "更新"}</button>
                </div>
            }
        </form>
    </>)
}
