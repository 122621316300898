import React from 'react';

const FormGroup = (props) => {
    const { label, required, children } = props;

    return (
        <div className="form-group">
            {
                label &&
                <div className="label-group">
                    <label>{ required && <span className="text-danger">* </span>}{ label }</label>
                </div>
            }
            { children }
        </div>
    )
}

const InputGroup = (props) => {
    const { children, desc, className } = props;

    return (
        <div className={`input-group ${className ? className : ''}`}>
            { children }
            { desc && <div className="input-desc">{ desc }</div> }
        </div>
    )
}

export { FormGroup, InputGroup };