import React, { useState, useEffect } from 'react';
import useForm from "react-hook-form";
import { FormattedMessage } from 'react-intl';
import { FormGroup, InputGroup } from '../../../common/FormGroup';
import Header from '../../../common/Header';
import { updateLocales } from '../../../store/actions';
import { updateLanguage } from '../../../services/authService';

export default function Page(props) {
    const { user, setSnack, setLoader } = props;
    const { register, handleSubmit, getValues, setValue } = useForm();
    const [ language, setLanguage ] = useState("");

    useEffect(() => {
        user.language && setLanguage(user.language);
    }, [user]);

    const onSubmit = (data) => {
        setLoader(true);
        updateLanguage(data).then(res => {
            props.dispatch(updateLocales({locale: language}));
            setLoader(false);
            setSnack({open: true, text: res.messages});
        }).catch(err => {
            setLoader(false);
            setSnack({open: true, text: err.messages.error});
        })
    }

    return (<>
        <Header title={<FormattedMessage id="app.config" />} scroll />
        <form className="common-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="flex-fill">
                <div className="common-title"><FormattedMessage id="app.config" /></div>
                <div className="form-container pd-3">
                    <FormattedMessage id="app.language">
                        {label=>
                            <FormGroup label={label}>
                                <InputGroup>
                                    <select
                                        name="language"
                                        value={language}
                                        onChange={(e) => setLanguage(e.target.value)}
                                        ref={register}
                                    >
                                        <option value="zh_tw" >繁體中文</option>
                                        <option value="zh_cn">简体中文</option>
                                        <option value="en">English</option>
                                    </select>
                                </InputGroup>
                            </FormGroup>
                        }
                    </FormattedMessage>
                    {/* <FormattedMessage id="app.exchangeRate">
                        {label=>
                            <FormGroup label={label}>
                                <InputGroup>
                                    <select
                                        name=""
                                        defaultValue="1"
                                        ref={register()}
                                    >
                                        <option value="1">新台幣 ( TWD )</option>
                                        <option value="2">人民幣 ( CNY )</option>
                                    </select>
                                </InputGroup>
                            </FormGroup>
                        }
                    </FormattedMessage> */}
                </div>
            </div>
            <div className="pdx-3 pdy-8">
                <button type="submit" className="submit-btn"><FormattedMessage id="app.submit" /></button>
            </div>
        </form>
    </>)
}