import React, { useState, useEffect } from 'react';
import useForm from "react-hook-form";
import { updatePassword } from '../../../services/authService';
import { FormGroup, InputGroup } from '../../../common/FormGroup';
import Header from '../../../common/Header';

export default function Page(props) {
    const { setSnack, setLoader } = props;
    const { register, handleSubmit, reset } = useForm();
    const [ oldPasswordShow, setOldPasswordShow ] = useState(false);
    const [ passwordShow, setPasswordShow ] = useState(false);
    const [ rePasswordShow, setRePasswordShow ] = useState(false);

    const onSubmit = (data) => {
        if(data.old_password === ""){
            setSnack({open: true, text: "請填寫舊密碼"});
            return;
        }
        if(data.password === ""){
            setSnack({open: true, text: "請填寫新密碼"});
            return;
        }
        if(data.password_confirmation === ""){
            setSnack({open: true, text: "請填寫確認密碼"});
            return;
        }
        if(data.password !== "" && data.password_confirmation !== ""){
            if(data.password_confirmation !== data.password) {
                setSnack({open: true, text: "兩者密碼不相同"});
                return;
            }
        }
        setLoader(true);
        updatePassword(data).then(res => {
            setLoader(false);
            reset();
            setSnack({open: true, text: res.messages});
        }).catch(err => {
            setLoader(false);
            setSnack({open: true, text: err.messages.error});
        })
    }

    return (<>
        <Header title="修改密碼" scroll />
        <form className="common-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="flex-fill">
                <div className="common-title">修改密碼</div>
                <div className="form-container pd-3">
                    <FormGroup label="舊密碼">
                        <InputGroup>
                            <input
                                tabIndex="1"
                                name="old_password"
                                type={oldPasswordShow ? "text" : "password"}
                                placeholder="請輸入舊密碼"
                                autoComplete="off"
                                spellCheck="false"
                                ref={register}
                            />
                            <div className="input-desc">
                                <button type="button" className="view-pass-btn" onClick={() => setOldPasswordShow(!oldPasswordShow)}>
                                    {oldPasswordShow ? (<i className="far fa-eye-slash"></i>) : (<i className="far fa-eye"></i>)}
                                </button>
                            </div>
                        </InputGroup>
                    </FormGroup>
                    <FormGroup label="新密碼">
                        <InputGroup>
                            <input
                                tabIndex="1"
                                name="password"
                                type={passwordShow ? "text" : "password"}
                                placeholder="請輸入新密碼"
                                autoComplete="off"
                                spellCheck="false"
                                ref={register}
                            />
                            <div className="input-desc">
                                <button type="button" className="view-pass-btn" onClick={() => setPasswordShow(!passwordShow)}>
                                    {passwordShow ? (<i className="far fa-eye-slash"></i>) : (<i className="far fa-eye"></i>)}
                                </button>
                            </div>
                        </InputGroup>
                    </FormGroup>
                    <FormGroup label="確認新密碼">
                        <InputGroup>
                            <input
                                tabIndex="1"
                                name="password_confirmation"
                                type={rePasswordShow ? "text" : "password"}
                                placeholder="確認密碼"
                                autoComplete="off"
                                spellCheck="false"
                                ref={register}
                            />
                            <div className="input-desc">
                                <button type="button" className="view-pass-btn" onClick={() => setRePasswordShow(!rePasswordShow)}>
                                    {rePasswordShow ? (<i className="far fa-eye-slash"></i>) : (<i className="far fa-eye"></i>)}
                                </button>
                            </div>
                        </InputGroup>
                    </FormGroup>
                </div>
            </div>
            <div className="pdx-3 pdy-8">
                <button type="submit" className="submit-btn">送出</button>
            </div>
        </form>
    </>)
}
