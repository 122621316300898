import * as ActionTypes from '../action-types'
import * as i18n from "../../../i18n";

const intl = {
    defaultLocale: 'zh_tw',
    locale: 'zh_tw',
    messages: i18n['zh_tw'],
}

const initialState = {
    intl
};

function localesReducer(state = initialState, {type, payload = null}) {
    switch (type) {
        case ActionTypes.UPDATE_LOCALES:
            return updateLocales(state, payload);
        default:
            return state
    }
}

const updateLocales = (state, payload) => {
    state = Object.assign({}, state, {
        intl: {...intl, ...payload, messages: i18n[payload.locale]}
    });
    return state;
}

export default localesReducer;