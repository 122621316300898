import React, { useState } from "react";
import { connect } from 'react-redux';

const AvatarImg = props => {
	const { type, id, name, register, width, height, border, className, fileSize, image, setSnack } = props;
	const [ uploadImage, setUploadImage ] = useState({preview: '', raw: ''});

    const handleChange = (e) => {
		if(fileSize){
			let fileMaxSize = fileSize*1024*1024;
			
			if(e.target.files[0].size > fileMaxSize){
				setSnack({open: true, text: `圖片上傳最大上限為${fileSize}MB`})
				return;
			}
		}

		const imgUrl = URL.createObjectURL(e.target.files[0]);

        setUploadImage({
            preview: imgUrl,
            raw: e.target.files[0]
		})

        props.parentCallback&&props.parentCallback(imgUrl);
	}
	
	return (
		<div
			className={`user-avatar-img ${ uploadImage.preview === "" ? !image ? "non-avatar" : "" : "" } ${className ? className : ""}`}
			style={{
				width: width ? width + 'px' : '100px',
				minWidth: width ? width + 'px' : '100px',
				height: height ? height+'px' : '100px',
				backgroundImage: `url(${ uploadImage.preview ? uploadImage.preview : image ? image : "" })`,
				border: border ? border : "0",
			}}
		>
			{
				type && type === "file" && (
				<input
					id={id}
					type="file"
					style={{width: width ? width+'px' : '100px', height: height ? height+'px' : '100px'}}
					name={name ? name : `file`}
					accept="image/gif, image/jpeg, image/png"
					onChange={handleChange}
					ref={register ? register : null}
				/>
				)
			}
		</div>
	)
}

const mapStateToProps = state => {
    return {
        user : state.Auth.user,
    }
}

export default connect(mapStateToProps)(AvatarImg);