import React, { useState } from 'react';

const initialState = {
    header: true,
    background: "",
    mainTitle: "",
    subTitle: "",
    footer: false,
    prevPath: "",
    setHeader: state => {},
    setMainTitle: state => {},
    setSubTitle: state => {},
    setFooter: state => {},
    setPrevPath: state => {},
    setBackground: state => {},
}

export const GlobalContext = React.createContext(initialState);

export const GlobalProvider = (props) => {
    const [ footer, setFooter ] = useState(initialState.footer);
    const [ header, setHeader ] = useState(initialState.header);
    const [ mainTitle, setMainTitle ] = useState(initialState.mainTitle);
    const [ subTitle, setSubTitle ] = useState(initialState.subTitle);
    const [ prevPath, setPrevPath ] = useState(initialState.prevPath);
    const [ background, setBackground ] = useState(initialState.background);

    return (
        <GlobalContext.Provider value={{header, mainTitle, subTitle, footer, prevPath, background, setFooter, setHeader, setMainTitle, setSubTitle, setPrevPath, setBackground}}>
            { props.children }
        </GlobalContext.Provider>
    )
}