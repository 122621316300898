import React, { useContext, useEffect } from "react";
import { Route, Redirect, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { GlobalContext } from '../store/context/GlobalContext';

const PrivateRoute = ({component: Component, isAuthenticated, ...rest}) => {
    const context = useContext(GlobalContext);
    const { setLocale, setLoader, setSnack, hasHeader, hasFooter, mainTitle, subTitle, background } = rest;
    const initialProps = { setLocale, setLoader, setSnack};

    useEffect(() => {
        context.setHeader(hasHeader);
        context.setMainTitle(mainTitle);
        context.setSubTitle(subTitle);
        context.setFooter(hasFooter);
        context.setBackground(background);
        window.scrollTo(0, 0);
    }, []);

    return (
        <Route {...rest} render={props => (
            isAuthenticated ? (
                <Component {...props} {...initialProps} />
            ) : (
                <Redirect to={{
                    pathname: '/login',
                    state: { from: props.location },
                }}/>
            )
        )} />
    );
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated : state.Auth.isAuthenticated,
    }
};

export default connect(mapStateToProps)(PrivateRoute);