import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Autoplay } from 'swiper';
import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';

const Banner = props => {
    const { data } = props;
    
    if(data.length === 0) {
        return (<></>)
    }

    SwiperCore.use([Pagination, Autoplay]);
    return (
        <div className="home-banner">
            <Swiper
                slidesPerView={1}
                grabCursor={true}
                autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                }}
                loop={true}
                // pagination={{
                //     bulletElement: "span",
                // }}
            >
                {
                    data.map((item, key) => 
                    <SwiperSlide key={key}>
                        <div className="banner-item" style={{backgroundImage: `url(${item.image})`}}></div>
                    </SwiperSlide>
                    )
                }
            </Swiper>
        </div>
    )
}

export default Banner;
