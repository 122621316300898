export const AUTH_CHECK = 'AUTH_CHECK';
export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_REFRESH_TOKEN = 'AUTH_REFRESH_TOKEN';
export const AUTH_RESET_PASSWORD = 'AUTH_RESET_PASSWORD';
export const AUTH_USER = 'AUTH_USER';
export const UPDATE_LOCALES = 'UPDATE_LOCALES';
export const SHOW_HOMENEWS = 'SHOW_HOMENEWS';
export const HIDE_HOMENEWS = 'HIDE_HOMENEWS';

export default {
  AUTH_CHECK,
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_REFRESH_TOKEN,
  AUTH_USER,
  UPDATE_LOCALES,
  SHOW_HOMENEWS,
  HIDE_HOMENEWS,
}