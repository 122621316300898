import React, { useState, useEffect, useCallback } from 'react';

export default function Page(props) {
    let mounted = true;
    const { setSnack, setLoader } = props;

    return (
        <div className="about-page">
            <div className="about-wrapper">
                <div className="logo-img"></div>
                <div className="text-center" style={{fontWeight: "bold", fontSize: "36px"}}>財神標會通</div>
                <div className="text-center" style={{fontWeight: "bold", fontSize: "24px", color: "#E63946"}}>積沙成塔, 財氣亨通</div>
                <div className="text-center mgy-3">
                    <div>Version Number</div>
                    <div>1 . 0 . 0</div>
                </div>
            </div>
            <div className="about-content">
                <div>關於我們內容</div>
                <div>關於我們內容</div>
                <div>關於我們內容</div>
                <div>關於我們內容</div>
                <div>關於我們內容</div>
                <div>關於我們內容</div>
                <div>關於我們內容</div>
                <div>關於我們內容</div>
                <div>關於我們內容</div>
                <div>關於我們內容</div>
                <div>關於我們內容</div>
                <div>關於我們內容</div>
                <div>關於我們內容</div>
                <div>關於我們內容</div>
                <div>關於我們內容</div>
                <div>關於我們內容</div>
                <div>關於我們內容</div>
                <div>關於我們內容</div>
                <div>關於我們內容</div>
                <div>關於我們內容</div>
            </div>
        </div>
    )
}
