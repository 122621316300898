import React, { useEffect } from "react";

const Loader = ({ loader, setLoader, setSnack }) => {
    

    useEffect(() => {
        let timeout;
        
        const handleToggle = (loader) => {
            if(loader) {
                document.body.classList.add('modal-open');
            } else {
                document.body.classList.remove('modal-open');
            }
        }

        handleToggle(loader);

        timeout = setTimeout(() => {
            if(loader) {
                setLoader(false);
                setSnack({open: true, text: '系統出錯，請重新開啟'})
            }
        }, 5000);

        return () => {
            document.body.classList.remove('modal-open');
            clearTimeout(timeout);
        }

    }, [loader]);
    
    return (
        loader &&
        <div className={`loader`}>
            <div className="loader-container d-flex justify-content-center align-items-center">
                <div className="loader-box">
                    <div className="loader-icon"></div>
                </div>
            </div>
        </div>
    );
}

export default Loader;