import React, { useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PrivateLayout from './Private';
import PublicLayout from './Public';
import { fetchUser } from '../services/authService'; 
import { updateLocales } from '../store/actions';
import { GlobalContext } from '../store/context/GlobalContext';

function Layout(props) {
    const { isAuthenticated, user, children, dispatch } = props;
    const context = useContext(GlobalContext);
    const { background } = context;

    useEffect(() => {
        if (isAuthenticated && !user.id) {
            dispatch(fetchUser())
        }
    }, [isAuthenticated]);


    useEffect(() => {
        background && document.body.classList.add(background);
        return () => {
            background && document.body.classList.remove(background);
        }
    }, [background]);

    useEffect(() => {
        user.language && dispatch(updateLocales({locale: user.language}))
    }, [user]);

    if (isAuthenticated) {
        return <PrivateLayout {...props}>{children}</PrivateLayout>
    }
    return <PublicLayout {...props}>{children}</PublicLayout>
}

const mapStateToProps = state => {
    return {
        isAuthenticated: state.Auth.isAuthenticated,
        user: state.Auth.user,
    }
}
  
export default withRouter(connect(mapStateToProps)(Layout));