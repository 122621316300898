import React, { useContext } from 'react';
import { GlobalContext } from '../store/context/GlobalContext';
import Header from './Header';
import Footer from './Footer';

export default function Public(props) {
    const { children } = props;
    const context = useContext(GlobalContext);
    
    return (<>    
        <Header />
        <main className={!context.header ? 'pt-0' : ''}>
			{ children }
		</main>
        <Footer/>
    </>)
}
