import React, { useEffect, useState, useRef } from 'react';
import styled, { css } from 'styled-components';
import { createPortal } from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import Draggable from 'react-draggable';

const overlayTransitionClass = `overlay-transition`;
const modalClass = `overlay-modal-box`;
let windowHeight = window.innerHeight;
let boxHeight = windowHeight - 30;
let headerHeight = 60;

// ${props => props.contextHeight
//     ? css `transform: translate(0, ${props.contextHeight + 50}px) !important`
//     : css `transform: translate(0, ${boxHeight}px) !important;`
// }

const OverLayBg = styled.div `
    visibility: hidden;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: 1000;
    transition: all 300ms ease-in-out;
    &.${overlayTransitionClass}-enter {
        visibility: visible !important;
        background-color: rgba(0,0,0,0) !important;
        .${modalClass} {
            transform: translate(0, ${boxHeight}px) !important;
        }
    }
    &.${overlayTransitionClass}-enter-active {
        visibility: visible !important;
        background-color: rgba(0,0,0,0.55) !important;
        transition: all 300ms ease-in-out !important;
        .${modalClass} {
            transform: translate(0, 0) !important;
            transition: all 1000ms cubic-bezier(.2,1,.2,1) !important;
        }
    }
    &.${overlayTransitionClass}-enter-done {
        visibility: visible !important;
        background-color: rgba(0,0,0,0.55) !important;
        .${modalClass} {
            transform: translate(0, 0px);
        }
    }
    &.${overlayTransitionClass}-exit{
        visibility: visible !important;
        background-color: rgba(0,0,0,0.55) !important;
        .${modalClass} {
            transform: translate(0, 0) !important;
        }
    }
    &.${overlayTransitionClass}-exit-active{
        visibility: hidden !important;
        background-color: rgba(0,0,0,0) !important;
        transition: all 300ms ease-in-out !important;
        .${modalClass} {
            transform: translate(0, ${boxHeight}px) !important;
            transition: all 1000ms cubic-bezier(.2,1,.2,1) !important;
        }
    }
    &.${overlayTransitionClass}-exit-done{
        visibility: hidden !important;
        background-color: rgba(0,0,0,0) !important;
        .${modalClass} {
            transform: translate(0, ${boxHeight}px) !important;
        }
    }
`

const OverLayContainer = styled.div `
    position: relative;
    ${props => props.contextHeight >= 250
        ? css `padding-top: ${(windowHeight - props.contextHeight - headerHeight) > 30 ? windowHeight - props.contextHeight - headerHeight : 30 }px;`
        : css `padding-top: ${windowHeight - 300}px;`
    }
    width: 100%;
    height: 100%;
    overflow-y: none;
    &::-webkit-scrollbar {
        display: none;
    }
`

const OverLayBox = styled.div `
    position: relative;
    transform: translate(0, 0);
    min-height: 300px;
    height: calc(100vh - 30px);
    max-height: calc(100vh - 30px);
    width: 100%;
    border-radius: 15px 15px 0 0;
    background: #fff;
    transition: all 1s cubic-bezier(.2,1,.2,1);
    overflow: hidden;
    &:before {
        content: "";
        position: absolute;
        top: 6px;
        left: 50%;
        transform: translateX(-50%);
        width: 50px;
        height: 4px;
        border-radius: 15px;
        background-color: rgba(0,0,0,0.2);
    }
`

const OverlayBoxHeader = styled.aside `
    height: ${headerHeight}px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    padding-top: 15px;
    border-bottom: 1px solid rgba(0,0,0,0.05);
`

const OverLayBoxContent = styled.div `
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    word-break: break-all;
    &::-webkit-scrollbar {
        display: none;
    }
    ${props => props.contextHeight > windowHeight-80 && css `height: calc(100% - ${headerHeight}px);`}
`

const OverLayBoxCloseButton = styled.button `
    border: 0;
    padding: 0;
    background-color: transparent;
    cursor: pointer;
    position: absolute;
    right: 10px;
    font-size: 18px;
    top: ${(headerHeight-15)/2}px;
`

const OverLayBottom = (props) => {
    const { title, className, children, open, toggle } = props;
    const ModalPortal = window.document.getElementById('modal-root');
    const modalRef = useRef(null);
    const containerRef = useRef(null);
    const contextRef = useRef(null);
    const [ contextHeight, setContextHeight ] = useState(0);

    useEffect(() => {
        open && document.body.classList.add("modal-open");

        open && setContextHeight(contextRef.current.clientHeight);

        if(!open){
            setTimeout(() => {
                setContextHeight(0);
            }, 300)
        }

        return () => {
            if(document.getElementById('modal-root').children.length <= 1) {
                document.body.classList.remove("modal-open");
            }
        }
    }, [open])

    const handleToggle = (e) => {
        e.stopPropagation();
        if(!modalRef.current.contains(e.target)) {
            toggle(false);
        }
    }

    const DraggableEventHandler = (e, data) => {
        data.lastY > 0 && toggle(false);
    }

    const Modal = (
        <CSSTransition in={open} timeout={300} classNames={overlayTransitionClass} unmountOnExit>
            <OverLayBg onClick={handleToggle} contextHeight={contextHeight}>
                <OverLayContainer
                    ref={containerRef}
                    contextHeight={contextHeight}
                >
                    <Draggable
                        axis="y"
                        bounds={{top: 0, bottom: windowHeight}}
                        onStop={DraggableEventHandler}
                        // scale={0.4}
                        handle="aside"
                    >
                        <OverLayBox
                            className={`${modalClass} ${className&&className}`}
                            ref={modalRef}
                        >
                            <OverlayBoxHeader>
                                <div className="font-weight-bold">{title}</div>
                            </OverlayBoxHeader>
                            <OverLayBoxContent ref={contextRef} contextHeight={contextHeight}>
                                { children }
                            </OverLayBoxContent>
                            <OverLayBoxCloseButton onClick={() => toggle(false)} >
                                <i className="fal fa-times-circle pd-1"></i>
                            </OverLayBoxCloseButton>
                            
                        </OverLayBox>
                    </Draggable>

                </OverLayContainer>
            </OverLayBg>
        </CSSTransition>
    )

    return createPortal(Modal, ModalPortal);
}

export default OverLayBottom;