import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment/moment.js';
import { Link } from 'react-router-dom';
import Skeleton from '@material-ui/lab/Skeleton';
import Header from '../../../common/Header';
import ConfirmBox from "../../../common/ConfirmBox";
import { getAllNotification, readAllNotification } from '../../../services/authService';

export default function Notification(props) {
    let mounted = true;
    const { setSnack, setLoader } = props;
    const [ loading, setLoading ] = useState(true);
    const [ data, setData ] = useState([]);
    const [ confirmBoxToggle, setConfirmBoxToggle] = useState(false);

    const fetchData = useCallback(async() => {
        let result = await getAllNotification();
        if(mounted){
            setData(result.data);
            setLoading(false);
        }
    }, [mounted])

    useEffect(() => {
        fetchData();
        return () => {
            mounted = false;
        }
    }, [fetchData])

    const handleConfirm = value => {
        setConfirmBoxToggle(value.open);
        if(value.confirm) {
            setLoader(true);
            readAllNotification().then(res => {
                fetchData();
                setLoader(false);
            }).catch(err => {
                setSnack({open: true, text: err.messages.error});
            })
        }
    }
    
    const PageLoading = () => {
        return (
            <ul className="notification-list">
                {Array(parseInt(20)).fill().map((item, key) => <SkeletonItem key={key} />)}
            </ul>
        )
    }

    return (
        <div className="notification-page">
            <Header title="通知中心" scroll right={<><button className="pd-0 mr-auto" style={{border: "0", backgroundColor: "transparent", fontSize: "12px", transform: "scale(1.1)"}} onClick={() => setConfirmBoxToggle(true)}>全部已讀</button></>} />
            <div className="common-title">通知中心</div>
            { 
                loading ? (
                    <PageLoading />
                ) : (
                    data.length > 0 ? (
                        <ul className="notification-list">
                            {data.map((item, key) => <NotificationItem item={item} key={key} />)}
                        </ul>
                    ) : (
                        <div>no data</div>
                    )
                )
            }
            <ConfirmBox
                open={confirmBoxToggle}
                confirmText="確定全部已讀嗎?"
                callback={value => handleConfirm(value)}
                cancel={{show: true, type: "button", text: "取消"}}
                confirm={{show: true, type: "submit", text: "確定"}}
            />
        </div>
    )
}

const SkeletonItem = () => {
    return (
        <li className="notification-item">
            <div className="notification-link">
                <div className="content">
                    <Skeleton animation="wave" height={15} width={200} style={{ borderRadius: 15, marginBottom: 10 }}/>
                    <Skeleton animation="wave" height={10} width={100} style={{ borderRadius: 10 }}/>
                </div>
                <div className="d-flex flex-column justify-content-between align-items-center">
                    <Skeleton animation="wave" height={10} width={40} style={{ borderRadius: 10, marginBottom: 10 }}/>
                </div>
            </div>
        </li>
    )
}

const NotificationItem = (props) => {
    const { item } = props;

    return (
        <li className={`notification-item ${item.read_at ? "read" : "unread"}`}>
            <Link to={`/notification/${item.id}`} className="notification-link">
                <div className="mgr-2">
                    <i className="fal fa-envelope mgt-1"></i>
                </div>
                <div className="content">
                    <div className="title">[{item.category}] {item.title}</div>
                    <div className="info">{item.content}</div>
                </div>
                <div className="d-flex flex-column justify-content-between align-items-center">
                    <div className="time">{moment(item.created_at).format("MM/DD")}</div>
                </div>
            </Link>
        </li>
    )
}