import React, { useState } from 'react';
import useForm from "react-hook-form";
import { forgotPhoneVerify } from '../../../../services/authService';
import Header from '../../../../common/Header';

const Step1 = ({ setForm, formData, navigation, setSnack, setLoader, ...props }) => {
    const { next } = navigation;
    const { register, handleSubmit } = useForm();
    const [ username, setUsername ] = useState("");

    const handleModeChange = (mode) => {
        setUsername("");
        setForm({
            target: {
                name: 'mode',
                value: mode
            }
        })
    }

    const handleStep1 = () => {
        let credentials = {};
        
        if(formData.mode === "phone"){
            if(username === ""){
                setSnack({open: true, text: "請填寫手機號碼"});
                return;
            }else{
                credentials = {phone: username, phone_code: formData.phone_code};
            }
        }
        if(formData.mode === "email"){
            if(username === ""){
                setSnack({open: true, text: "請填寫郵箱地址"});
                return;
            }else{
                credentials = {email: username};
            }
        }

        setLoader(true);
        forgotPhoneVerify(credentials).then(res => {
            setLoader(false);
            setForm({
                target: {
                    name: formData.mode,
                    value: username
                }
            })
            next();
        }).catch(err => {
            setLoader(false);
            setSnack({open: true, text: err.messages.error});
        })
    }

    return (<>
        <Header title="重置密碼" scroll back={() => props.history.replace("/login")} /> 
        <div className="flex-fill">
            <div className="common-title">重置密碼</div>
            <div className="pd-3">
                <div className="form-group">
                    <div className="label-group">
                        {
                            formData.mode === "phone" && (<>
                                <label>手機號碼</label>
                                {/* <div className="font-weight-bold text-main" style={{cursor: 'pointer'}} onClick={() => handleModeChange("email")}>切換至信箱登入</div> */}
                            </>)
                        }
                        {
                            formData.mode === "email" && (<>
                                <label>信箱</label>
                                <div className="font-weight-bold text-main" style={{cursor: 'pointer'}} onClick={() => handleModeChange("phone")}>切換至手機登入</div>
                            </>)
                        }
                    </div>
                    <div className="input-group">
                        {
                            formData.mode === "phone" ? (<>
                                <select
                                    name="phone_code"
                                    style={{maxWidth: "80px"}}
                                    value={formData.phone_code}
                                    ref={register}
                                    onChange={(e) => setForm({
                                        target: {
                                            name: 'phone_code',
                                            value: e.target.value
                                        }
                                    })}
                                >
                                    <option value="+886">+886</option>
                                    <option value="+86">+86</option>
                                </select>
                                <input
                                    tabIndex="1"
                                    name="phone"
                                    type="tel"
                                    placeholder="手機號碼"
                                    autoComplete="off"
                                    spellCheck="false"
                                    ref={register({required: true})}
                                    onChange={(e) => setUsername(e.target.value)}
                                />
                            </>) : (
                                <input
                                    tabIndex="1"
                                    name="email"
                                    type="text"
                                    placeholder="郵箱地址"
                                    autoComplete="off"
                                    spellCheck="false"
                                    ref={register({required: true})}
                                    onChange={(e) => setUsername(e.target.value)}
                                />
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
        <div className="pdx-3 pdy-8">
            <button type="button" className="submit-btn" onClick={() => handleStep1()}>下一步</button>
        </div>
    </>)
}

export default Step1;