import React, { useState, useEffect } from 'react';
import useForm from "react-hook-form";
import AvatarImg from '../../components/AvatarImg';
import { FormGroup, InputGroup } from '../../../common/FormGroup';
import { updateProfile, fetchUser } from '../../../services/authService';
import Header from '../../../common/Header';

export default function Page(props) {
    const { user, setSnack, setLoader } = props;
    const { register, handleSubmit, setValue, getValues } = useForm();

    const onSubmit = (data) => {
        if(data.avatar.length === 0){
            delete data.avatar;
        }

        const formData = new FormData();
		Object.keys(data).map(function(key, index) {
			return key==='avatar' ? formData.append(key, data.avatar[0]) : formData.append(key, data[key])
        });

        setLoader(true);
        updateProfile(formData)
        .then(res => {
            setLoader(false);
            setSnack({open: true, text: res.messages});
            props.dispatch(fetchUser());
        }).catch(err => {
            setLoader(false);
            setSnack({open: true, text: err.messages.name});
        })
    }

    return (<>
        <Header title="修改個人資料" scroll />
        <form className="common-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="flex-fill">
                <div className="common-title">修改個人資料</div>
                <div className="form-container pd-3">
                    <div className="pdy-2">
                        <AvatarImg
                            name="avatar"
                            type="file"
                            width="90"
                            height="90"
                            border="1px solid rgba(0,0,0,0.1)"
                            className="m-auto mb-3 register"
                            setSnack={setSnack}
                            fileSize="3"
                            image={user.avatar}
                            register={register}
                        />
                    </div>
                    <FormGroup label="真實姓名">
                        <InputGroup>
                            <input
                                type="text"
                                defaultValue={user.name}
                                readOnly
                            />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup label="帳號">
                        <InputGroup>
                            <input
                                type="text"
                                defaultValue={user.phone}
                                readOnly
                            />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup label="邀請碼">
                        <InputGroup>
                            <input
                                type="text"
                                defaultValue={user.invitation_token}
                                readOnly
                            />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup label="推薦人">
                        <InputGroup>
                            <input
                                type="text"
                                defaultValue={user.parent_name}
                                readOnly
                            />
                        </InputGroup>
                    </FormGroup>
                </div>
            </div>
            <div className="pdx-3 pdy-8">
                <button type="submit" className="submit-btn">更新</button>
            </div>
        </form>
    </>)
}