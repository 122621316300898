import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { IntlProvider } from "react-intl";
import { connect } from 'react-redux';
import { GlobalProvider } from '../store/context/GlobalContext';
import routes from './routes';
import Layout from '../layout';
import PrivateRoute from './Private';
import PublicRoute from './Public';
import Loader from '../common/Loader';
import SnackBar from "../common/SnackBar";

const Routes = (props) => {
    const { messages } = props;
    const [ locale, setLocale ] = useState(navigator.language);
    const [ snack, setSnack ] = useState({open: false, color:'', text:''});
    const [ loader, setLoader ] = useState(false);

    const initialProps = { locale, setSnack, setLoader };

    return (
        <IntlProvider key={locale} locale={locale} messages={messages}>
            <GlobalProvider>
                <Router>
                    <Layout {...initialProps}>
                        <Switch>
                            {routes.map((route, i) => (
                                route.auth ? (
                                    <PrivateRoute key={i} {...route} {...initialProps}/>
                                ) : (
                                    <PublicRoute key={i} {...route} {...initialProps}/>
                                )
                            ))}
                        </Switch>
                        <SnackBar {...snack} callback={(e) => setSnack(e)}/>
                        <Loader loader={loader} setLoader={setLoader} setSnack={setSnack}/>
                    </Layout>
                </Router>
            </GlobalProvider>
        </IntlProvider>
    );
}

const mapStateToProps = state => {
    return {
        locale: state.locales.intl.locale,
        messages: state.locales.intl.messages
    }
}

export default connect(mapStateToProps)(Routes);
