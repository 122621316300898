import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import useForm from "react-hook-form";
import TextField from '@material-ui/core/TextField';
import AuthService from '../../../services';
import * as action from '../../../store/actions';
import { GlobalContext } from '../../../store/context/GlobalContext';

export default function Page(props) {
    const context = useContext(GlobalContext);
    const { isAuthenticated, setSnack, setLoader } = props;
    const { register, handleSubmit } = useForm();
    const [ passwordShow, setPasswordShow ] = useState(false);

    useEffect(() => {
        props.location.state && context.setPrevPath(props.location.state.from.pathname);
        return () => {
            context.setPrevPath("");
        }
    }, [])

    useEffect(() => {
        isAuthenticated && props.history.push("/dashboard");
    }, [isAuthenticated])

    const onSubmit = (data) => {
        if(data.phone === ""){
            setSnack({open: true, text: "請填寫手機號碼"});
            return;
        }
        if(data.password.length < 4){
            setSnack({open: true, text: "密碼最少為4位"});
            return;
        }
        setLoader(true);
        AuthService.login(data).then(res => {
            setLoader(false);
            props.dispatch(action.authLogin(res));
            setSnack({open: true, text: "登入成功"});
            let redirectPath = context.prevPath;
            context.setPrevPath("");
            props.history.push(redirectPath !== "" ? redirectPath : "/dashboard");
        }).catch(err => {
            setLoader(false);
            setSnack({open: true, text: err.messages.error});
        })
    }

    return (
        <div className="login-page">
            <div className="pdt-6">
			    <div className="logo"></div>
            </div>
            <div className="title">APE會員繳費查詢系統</div>
            <div className="logo-wrapper">
                <div className="content-box">
                    <form role="form" id="form" onSubmit={handleSubmit(onSubmit)}>
                        <div className="d-flex flex-nowrap mb-3">
                            <div className="input-group-prepend">
                                <div className="input-group-text"><i className="fas fa-user"></i></div>
                            </div>
                            <div className="d-flex flex-fill">
                                <select
                                    className="form-control custom-select phone-select"
                                    name="phone_code"
                                    ref={register}
                                >
                                    <option value="+886">+886</option>
                                </select>
                                <input
                                    tabIndex="1"
                                    name="phone"
                                    type="tel"
                                    className="form-control flex-fill"
                                    placeholder="手機號碼"
                                    autoComplete="new-username"
                                    spellCheck="false"
                                    ref={register}
                                />
                            </div>
                        </div>
                        <div className="d-flex flex-nowrap mb-3" style={{position: 'relative'}}>
                            <div className="input-group-prepend">
                                <div className="input-group-text"><i className="fal fa-lock-alt"></i></div>
                            </div>
                            <input
                                tabIndex="1"
                                type={passwordShow ? 'text' : 'password'}
                                className="form-control"
                                name="password"
                                placeholder="輸入密碼"
                                autoComplete="new-password"
                                spellCheck="false"
                                ref={register}
                            />
                            <a className="position-absolute view-pass-btn" onClick={() => setPasswordShow(!passwordShow)}>
                                {passwordShow ? (<i className="far fa-eye-slash"></i>) : (<i className="far fa-eye"></i>)}
                            </a>
                        </div>
                        <div className="mb-3 font-size-xs text-danger">* 密碼預設為身份證後4碼</div>
                        <button 
                            type="submit"
                            className="submit-btn mb-3"
                        >
                            登入
                        </button>
                        <div className="d-flex justify-content-end other-description">
                            {/* <Link to="/register" className="text-info">註冊會員</Link> */}
                            <Link to="/forget" className="text-secondary">忘記密碼 ?</Link>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}