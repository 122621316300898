import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';

const CommonHeader = (props) => {
    let history = useHistory();
    const { title, right, back, background, color, scroll } = props;
    const [ showTitle, setShowTitle ] = useState(false);

    useScrollPosition(({ prevPos, currPos }) => {
        if( window.scrollY > 36 && scroll){
            setShowTitle(true);
        }else{
            setShowTitle(false);
        }
    }, [showTitle],
        false,
        false,
        0
    )
    
    return(
        <header
            className="common-header"
            style={{
                backgroundColor: background ? background : 'rgba(255,255,255,1)',
                color: color ? color : '#555',
            }}
        >
            <nav>
                <div className="nav-item nav-left">
                    <div className="back-btn" onClick={back ? back : () => history.goBack()}><i className="far fa-chevron-left"></i></div>
                </div>
                <div className="nav-item nav-title">{showTitle && title}</div>
                <div className="nav-item nav-right">{right}</div>
            </nav>
        </header>
    )
}

export default CommonHeader;