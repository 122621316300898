import React, { useState, useEffect, useCallback } from 'react';
import useForm from "react-hook-form";
import { getERC20, erc20Verify, fetchUser } from '../../../services/authService';
import { FormGroup, InputGroup } from '../../../common/FormGroup';
import Header from '../../../common/Header';

export default function Page(props) {
    let mounted = true;
    const { user, setSnack, setLoader } = props;
    const { register, handleSubmit, reset, setValue } = useForm();
    const [ erc20, setErc20 ] = useState({});

    const fetchData = useCallback(async() => {
        setLoader(true);
        let result = await getERC20();
        if(mounted){
            setErc20(result.data.content);
            setLoader(false);
        }
    }, [mounted])
    
    useEffect(() => {
        fetchData();
        return () => mounted = false;
    }, [fetchData])

    const onSubmit = (data) => {
        console.log(data);
        if(data.address === ""){
            setSnack({open: true, text: "請填寫錢包地址"});
            return;
        }
        setLoader(true);
        erc20Verify(data).then(res => {
            setLoader(false);
            props.dispatch(fetchUser());
            setSnack({open: true, text: res.messages});
        }).catch(err => {
            setLoader(false);
            setSnack({open: true, text: err.messages.error});
        })
    }

    const AuthStatus = (status) => {
        switch (status) {
            case 0: return(<div className="font-size-ss text-white badge bg-secondary mgl-2">尚未認證</div>);
            case 2: return(<div className="font-size-ss text-white badge bg-success mgl-2">認證成功</div>);
            default: return;
        }
    }

    return (<>
        <Header title="電子錢包" scroll />
        <form className="common-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="flex-fill">
                <div className="common-title">電子錢包 {AuthStatus(user.erc20_verified)}</div>
                <div className="form-container pd-3">
                    <FormGroup label="錢包類型">
                        <InputGroup>
                            <select
                                name="type"
                                defaultValue={erc20.type}
                                onChange={(e) => {return;}}
                                ref={register}
                            >
                                <option value="erc20">ERC20</option>
                            </select>
                        </InputGroup>
                    </FormGroup>
                    <FormGroup label="錢包地址">
                        <InputGroup>
                            <input
                                tabIndex="1"
                                name="address"
                                type="text"
                                placeholder="錢包地址"
                                autoComplete="off"
                                spellCheck="false"
                                ref={register}
                                defaultValue={erc20.address}
                            />
                        </InputGroup>
                    </FormGroup>
                </div>
            </div>
            <div className="pdx-3 pdy-8">
                <button type="submit" className="submit-btn">{user.erc20_verified === 2 ? "更新" : "送出"}</button>
            </div>
        </form>
    </>)
}
