import React, { useState, useEffect, useCallback } from 'react';
import useForm from "react-hook-form";
import { getIdentity, identityVerify, fetchUser } from '../../../services/authService';
import { FormGroup, InputGroup } from '../../../common/FormGroup';
import Upload from '../../../common/Upload';
import Header from '../../../common/Header';

export default function Page(props) {
    let mounted = true;
    const { user, setSnack, setLoader } = props;
    const { register, watch, handleSubmit, reset, setValue } = useForm();
    const watchAllFields = watch();
    const [ authType, setAuthType ] = useState("idcard");
    const [ idcard, setIdcard ] = useState({
        image:{
            image_front: "",
            image_back: "",
        }
    });
    const [ passport, setPassport ] = useState({
        image:{
            image_passport: "",
        }
    });

    const fetchData = useCallback(async() => {
        setLoader(true);
        let result = await getIdentity();
        if(mounted){
            if(result.data.type === "idcard"){
                setIdcard({...result.data, image: {...idcard.image, ...result.data.image}})
            }
            if(result.data.type === "passport"){
                setPassport({...result.data, image: {...passport.image, ...result.data.image}})
            }
            setAuthType(result.data.type);
            setLoader(false);
        }
    }, [mounted])
    
    useEffect(() => {
        fetchData();
        return () => mounted = false;
    }, [fetchData])

    const onSubmit = (data) => {
        if(data.name === ""){
            setSnack({open: true, text: "請填寫身份證姓名"});
            return;
        }
        if(data.number === ""){
            setSnack({open: true, text: "請填寫身份證字號"});
            return;
        }
        
        if(authType === "idcard"){
            if(data.image_front.length === 0 && idcard.image.image_front === ""){
                setSnack({open: true, text: "請上傳圖片 (身份證正面)"});
                return;
            }
            if(data.image_back.length === 0 && idcard.image.image_back === ""){
                setSnack({open: true, text: "請上傳圖片 (身份證反面)"});
                return;
            }

            if(idcard.image && idcard.image.image_front !=="" && data.image_front.length === 0) {
                delete data.image_front;
            }
    
            if(idcard.image && idcard.image.image_back !=="" && data.image_back.length === 0) {
                delete data.image_back;
            }
        }

        if(authType === "passport"){
            console.log(passport.image.image_passport)
            if(data.image_passport.length === 0 && passport.image.image_passport === ""){
                setSnack({open: true, text: "請上傳圖片 (護照)"});
                return;
            }
            if(passport.image && passport.image.image_passport !=="" && data.image_passport.length === 0) {
                delete data.image_passport;
            }
        }

        const formData = new FormData();
		Object.keys(data).map(function(key, index) {
            if(key === 'image_front') return formData.append(key, data.image_front[0]);
            if(key === 'image_back') return formData.append(key, data.image_back[0]);
            if(key === 'image_passport') return formData.append(key, data.image_passport[0]);
			return formData.append(key, data[key]);
        });

        setLoader(true);
        identityVerify(formData).then(res => {
            props.dispatch(fetchUser());
            fetchData();
            if(authType === "idcard") setPassport({image:{image_passport: ""}})
            if(authType === "passport") setIdcard({image:{image_front: "",image_back: ""}})
            setSnack({open: true, text: res.messages});
            setLoader(false);
        }).catch(err => {
            setLoader(false);
            setSnack({open: true, text: err.messages.error});
        })
    }

    const AuthStatus = (status) => {
        switch (status) {
            case -1: return (<div className="font-size-ss text-white badge bg-danger mgl-2">審核失敗</div>);
            case 0: return(<div className="font-size-ss text-white badge bg-secondary mgl-2">尚未認證</div>);
            case 1: return(<div className="font-size-ss text-white badge bg-warning mgl-2">審核中</div>);
            case 2: return(<div className="font-size-ss text-white badge bg-success mgl-2">認證成功</div>);
            default: return;
        }
    }

    return (<>
        <Header title="身份驗證" scroll />
        <form className="common-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="flex-fill">
                <div className="common-title">身份驗證 {AuthStatus(user.identity_verified)}</div>
                <div className="form-container pd-3">
                    <FormGroup label="驗證類別">
                        <InputGroup>
                            {
                                user.identity_verified === 2 ? (
                                    <input
                                        type="text"
                                        value={authType === "idcard" ? "身份證驗證" : "護照驗證"}
                                        onChange={() => {return;}}
                                        readOnly
                                    />
                                ) : (
                                    <select
                                        name="type"
                                        defaultValue={authType}
                                        onChange={(e) => setAuthType(e.target.value)}
                                        ref={register}
                                    >
                                        {/* <option value="">請選擇驗證類別</option> */}
                                        <option value="idcard" selected={authType === "idcard"}>身份證驗證</option>
                                        {/* <option value="passport" selected={authType === "passport"}>護照驗證</option> */}
                                    </select>
                                )
                            }
                        </InputGroup>
                    </FormGroup>
                    { authType === "idcard" ? <IDCardForm user={user} data={idcard} register={register} setValue={setValue}/> : <PassportForm user={user} data={passport} register={register} setValue={setValue}/> }
                </div>
            </div>
            {
                user.identity_verified !== 2 &&
                <div className="pdx-3 pdy-8">
                    <button type="submit" className="submit-btn">送出</button>
                </div>
            }
        </form>
    </>)
}

const IDCardForm = (props) => {
    const { user, data, register, setValue } = props;
    return (<>
        <FormGroup label="身份證姓名">
            <InputGroup>
                <input
                    tabIndex="1"
                    name="name"
                    type="text"
                    placeholder={user.identity_verified !==2 ? "身份證姓名" : ""}
                    autoComplete="off"
                    spellCheck="false"
                    ref={register}
                    defaultValue={data.name}
                    readOnly={user.identity_verified === 2}
                />
            </InputGroup>
        </FormGroup>
        <FormGroup label="身份證字號">
            <InputGroup>
                <input
                    tabIndex="1"
                    name="number"
                    type="text"
                    placeholder={user.identity_verified !==2 ? "身份證字號" : ""}
                    autoComplete="off"
                    spellCheck="false"
                    ref={register}
                    defaultValue={data.number}
                    readOnly={user.identity_verified === 2}
                />
            </InputGroup>
        </FormGroup>
        <FormGroup label="上傳圖片 (身份證正面)">
            <Upload
                id="image_front"
                maxWidth="auto"
                maxHeight="200px"
                name="image_front"
                valid={{type: ["image/jpeg", "image/png"]}}
                register={register}
                clearCallback={() => setValue("image_front", "")}
                defaultValue={data.image && data.image.image_front && data.image.image_front}
                readOnly={user.identity_verified === 2}
            />
        </FormGroup>
        <FormGroup label="上傳圖片 (身份證反面)">
            <Upload
                id="image_back"
                maxWidth="auto"
                maxHeight="200px"
                name="image_back"
                valid={{type: ["image/jpeg", "image/png"]}}
                register={register}
                clearCallback={() => setValue("image_back", "")}
                defaultValue={data.image && data.image.image_back && data.image.image_back}
                readOnly={user.identity_verified === 2}
            />
        </FormGroup>
    </>)
}

const PassportForm = (props) => {
    const { user, data, register, setValue } = props;
    return (<>
        <FormGroup label="護照姓名">
            <InputGroup>
                <input
                    tabIndex="1"
                    name="name"
                    type="text"
                    placeholder={user.identity_verified !==2 ? "護照姓名" : ""}
                    autoComplete="off"
                    spellCheck="false"
                    ref={register}
                    defaultValue={data.name}
                    readOnly={user.identity_verified === 2}
                />
            </InputGroup>
        </FormGroup>
        <FormGroup label="護照號碼">
            <InputGroup>
                <input
                    tabIndex="1"
                    name="number"
                    type="text"
                    placeholder={user.identity_verified !==2 ? "護照號碼" : ""}
                    autoComplete="off"
                    spellCheck="false"
                    ref={register}
                    defaultValue={data.number}
                    readOnly={user.identity_verified === 2}
                />
            </InputGroup>
        </FormGroup>
        <FormGroup label="上傳圖片 (護照)">
            <Upload
                id="image_passport"
                maxWidth="auto"
                maxHeight="200px"
                name="image_passport"
                valid={{type: ["image/jpeg", "image/png"]}}
                register={register}
                clearCallback={() => setValue("image_passport", "")}
                defaultValue={data.image && data.image.image_passport && data.image.image_passport}
                readOnly={user.identity_verified === 2}
            />
        </FormGroup>
    </>)
}