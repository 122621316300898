import React, { useEffect } from 'react';
import { useForm, useStep } from "react-hooks-helper";
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';

const steps = [
    { id: "step1" },
    { id: "step2" },
    { id: "step3" },
];

const defaultData = {
    mode: "phone",
    verify: "",
    phone_code: "+886",
    password: "",
    password_confirmation: "",
};

const MultiStepForm = ({setSnack, setLoader, dispatch, history }) => {
    const [ formData, setForm ] = useForm(defaultData);
    const { step, navigation } = useStep({ initialStep: 0, steps });
    const { id } = step;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [step]);

    const props = { formData, setForm, navigation, setSnack, setLoader, dispatch, history };

    switch (id) {
        case "step1":
          return <Step1 {...props} />;
        case "step2":
          return <Step2 {...props} />;
        case "step3":
          return <Step3 {...props} />;
        default:
          return null;
      }
};

export default MultiStepForm;