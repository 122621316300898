import React, { useState } from 'react';
import { Link } from 'react-router-dom';

export default function Page(props) {
    const [ quantifyType, setQuantifyType ] = useState("1");

    const quantifyData = [
        {
            id: "1",
            status: "1",
        },
        {
            id: "2",
            status: "0",
        },
    ]

    return (
        <div className="quantify-page">
            <div className="main-header">
                <div className="d-flex align-items-center">
                    <i className="common-icon quantify"></i>
                    <div className="title">量化</div>
                </div>
            </div>
            <div className="bg-white pdt-3 pdx-3 mgb-3">
                <div className="d-flex">
                    <ToolsBox link="/dashboard/exchange" title="燃料餘額" icon="fal fa-fire fa-lg" color="#E63946" >
                        <div className="content">36,234.1234</div>
                    </ToolsBox>
                    <ToolsBox link="/dashboard/wallet" title="錢包餘額" icon="fal fa-wallet fa-lg" color="#2EC4B6" >
                        <div className="content">123.7413</div>
                    </ToolsBox>
                </div>
            </div>
            <div className="bg-white mgy-3">
                <h5 className="font-weight-bold-xl pdt-3 pdx-3">常用功能</h5>
                <ul className="tools-list">
                    <li className="tools-item">
                        <Link to="/dashboard/robot">
                            <i className={`common-icon robot`} style={{width: "36px"}}></i>
                            <div className="label">開通機器人</div>
                        </Link>
                    </li>
                    <li className="tools-item">
                        <Link to="/dashboard/api-manage">
                            <i className={`common-icon api-manage`} style={{width: "36px"}}></i>
                            <div className="label">API管理</div>
                        </Link>
                    </li>
                    <li className="tools-item">
                        <Link to="/dashboard/user-guide">
                            <i className={`common-icon guide`} style={{width: "36px"}}></i>
                            <div className="label">使用指南</div>
                        </Link>
                    </li>
                    <li className="tools-item">
                        <Link to="# ">
                            <i className={`common-icon order`} style={{width: "36px"}}></i>
                            <div className="label">跟單推薦</div>
                        </Link>
                    </li>
                </ul>
            </div>
            <div className="bg-white mgy-3">
                <div className="pd-3 d-flex justify-content-between align-items-center">
                    <h5 className="font-weight-bold-xl mgb-0">交易列表</h5>
                    <Link to="/quantify/create" className="create-btn"><i className="fal fa-plus mgr-1"></i>新增</Link>
                </div>
                <div className="pdx-3 mgb-2">
                    <button type="button" className={`quantify-btn ${quantifyType === "1" ? "active" : ""}`} onClick={() => setQuantifyType("1")}>火幣 (2)</button>
                    <button type="button" className={`quantify-btn ${quantifyType === "2" ? "active" : ""}`} onClick={() => setQuantifyType("2")}>幣安 (0)</button>
                </div>
                <ul className="quantify-list">
                    { quantifyData.map((item, key) => <QuantifyItem item={item} key={key} />) }
                </ul>
            </div>
        </div>
    )
}

const QuantifyItem = (props) => {
    const { item } = props;

    return (
        <li className="quantify-item">
            <Link to={`/quantify/detail/${item.id}`} className="quantify-link">
                <div className="pdy-2 d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">Bitcoin<i className="common-icon icon-sm search mgl-1"></i></div>
                    <div className={`quantify-status ${item.status === "1" ? "active" : ""}`}>{item.status === "1" ? "運行中" : "停止中"}</div>
                </div>
                <div className="d-flex flex-wrap">
                    <div className="quantify-content">
                        <div className="label">今日收益 (USDT)</div>
                        <div>+6.1345</div>
                    </div>
                    <div className="quantify-content">
                        <div className="label">單位</div>
                        <div>428.414</div>
                    </div>
                    <div className="quantify-content">
                        <div className="label">量化收入</div>
                        <div>$120.5410</div>
                    </div>
                    <div className="quantify-content">
                        <div className="label">投資 (USDT)</div>
                        <div>+6.1345</div>
                    </div>
                    <div className="quantify-content">
                        <div className="label">幣價</div>
                        <div>230,001</div>
                    </div>
                    <div className="quantify-content">
                        <div className="label">狀態</div>
                        <div>AI運行中</div>
                    </div>
                </div>
            </Link>
        </li>
    )
}

const ToolsBox = (props) => {
    const { link, icon, title, children, wrap, color } = props;
    
    return (
        <Link to={link} className={`tools-box ${wrap ? 'd-flex justify-content-between align-items-center' : ''}`}>
            <div className="d-flex align-items-center justify-content-between">
                <div className="font-weight-bold font-size-md">{title}</div>
                <i className={`mgr-1 ${icon}`} style={{color: color}}></i>
            </div>
            <div className="d-flex align-items-center">
                { children }
            </div>
        </Link>
    )
}