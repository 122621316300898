import React, { useState } from 'react';

export default function Page() {

    return (
        <div className="community-page">
            <div className="main-header">
                <div className="d-flex align-items-center">
                    <i className="common-icon bid"></i>
                    <div className="title">標會</div>
                </div>
            </div>

        </div>
    )
}