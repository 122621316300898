import React, { useEffect } from 'react';
import MultiStepForm from './Step/MultiStepForm';

export default function Page(props) {
    const { setSnack, setLoader, history } = props;

    return (
        <div className="d-flex flex-column h-100" style={{paddingTop: "60px"}}>
            <MultiStepForm setSnack={setSnack} setLoader={setLoader} history={history} />
        </div>
    )
}