import React from 'react'
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Virtual, Autoplay } from 'swiper';
import 'swiper/swiper.scss';

SwiperCore.use([ Virtual, Autoplay ]);

const HomeNews = (props) => {
    const { data } = props;

    return (
        <div className="home-news">
            <i className="far fa-volume-down fa-lg"></i>
            <Swiper
                spaceBetween={40}
                loop={true}
                direction="vertical"
                slidesPerView={1}
                simulateTouch={false}
                allowTouchMove={false}
                noSwiping={true}
                autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                }}
                >
                { data.map((item, key) => 
                    <SwiperSlide key={key}>
                        <Link to="# " className="d-block">
                            <div className="flex-fill mx-2 text-ellipsis">{item.title}</div>
                        </Link>
                    </SwiperSlide>
                )}
            </Swiper>
            <i className="common-icon sort"></i>
        </div>
    )
}

export default HomeNews;