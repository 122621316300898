import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import useForm from "react-hook-form";
import { forgotSubmit } from '../../../../services/authService';
import Header from '../../../../common/Header';

const Step3 = ({ setForm, formData, navigation, setSnack, setLoader, ...props }) => {
    let history = useHistory();
    const { previous, next } = navigation;
    const { register, handleSubmit } = useForm();
    const [ passwordShow, setPasswordShow ] = useState(false);

    const handleStep3 = () => {
        let credentials = {
            verify: formData.verify,
            password: formData.password,
            password_confirmation: formData.password_confirmation,
        };

        if(formData.password === ""){
            setSnack({open: true, text: "請輸入新密碼"});
            return;
        }
        if(formData.password_confirmation === ""){
            setSnack({open: true, text: "請輸入確認密碼"});
            return;
        }
        if(formData.password !== "" && formData.password_confirmation !== ""){
            if(formData.password_confirmation !== formData.password) {
                setSnack({open: true, text: "兩者密碼不相同"});
                return;
            }
        }

        if(formData.mode === "phone"){
            credentials = {...credentials, phone: formData.phone, phone_code: formData.phone_code};
        }
        if(formData.mode === "email"){
            credentials = {...credentials, email: formData.email,};
        }
        
        setLoader(true);
        forgotSubmit(credentials).then(res => {
            setLoader(false);
            setSnack({open: true, text: res.messages});
            history.replace("/login");
        }).catch(err => {
            setLoader(false);
            previous();
        })
    }

    return (<>
        <Header title="設定新密碼" scroll back={() => props.history.replace("/login")} /> 
        <div className="flex-fill">
            <div className="common-title">設定新密碼</div>
            <div className="pd-3">
                <div className="form-group">
                    <div className="label-group">
                        <label>新密碼</label>
                    </div>
                    <div className="input-group">
                        <input
                            tabIndex="1"
                            name="password"
                            type={passwordShow ? "text" : "password"}
                            style={{paddingRight: "40px"}}
                            placeholder="請輸入新密碼"
                            autoComplete="off"
                            spellCheck="false"
                            ref={register({required: true})}
                            onChange={(e) => setForm({
                                target: {
                                    name: 'password',
                                    value: e.target.value
                                }
                            })}
                        />
                        <div className="input-desc">
                            <button type="button" className="view-pass-btn" onClick={() => setPasswordShow(!passwordShow)}>
                                {passwordShow ? (<i className="far fa-eye-slash"></i>) : (<i className="far fa-eye"></i>)}
                            </button>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="label-group">
                        <label>確認新密碼</label>
                    </div>
                    <div className="input-group">
                        <input
                            tabIndex="1"
                            name="password_confirmation"
                            type="password"
                            placeholder="確認密碼"
                            autoComplete="off"
                            spellCheck="false"
                            ref={register({required: true})}
                            onChange={(e) => setForm({
                                target: {
                                    name: 'password_confirmation',
                                    value: e.target.value
                                }
                            })}
                        />
                    </div>
                </div>
            </div>
        </div>
        <div className="pdx-3 pdy-8">
            <button type="button" className="submit-btn" onClick={() => handleStep3()}>送出</button>
        </div>
    </>)
}

export default Step3;