import React from "react";
import ReactDOM from 'react-dom/client';
import store from './js/store';
import { authCheck } from './js/store/actions';
import AuthService from './js/services';
import App from './App';
import './App.scss';
import {
    QueryClient, QueryClientProvider
  } from '@tanstack/react-query'

store.dispatch(authCheck());

if (localStorage.getItem('jwt_token')) {
    // store.dispatch(AuthService.users());
}

const queryClient = new QueryClient()

ReactDOM.createRoot(document.getElementById('app')).render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <App />
        </QueryClientProvider>
    </React.StrictMode>,
);