import React, { useEffect, useContext } from "react";
import { Link, useHistory } from 'react-router-dom';
import { GlobalContext } from '../store/context/GlobalContext';
// import Notification from "../modules/Home/components/Notification";

export default function Header() {
    let history = useHistory();
    const context = useContext(GlobalContext);
    const { header, mainTitle, subTitle, prevPath} = context;
    
    // if(!header) return <></>;

    return (
        <header className={`user-header ${!header ? 'no-header' : ''}`}>
            <nav className={`${header ? 'd-flex' : 'd-none'}`}>
                <div className="nav-item nav-left">
                    { !mainTitle && <div className="back-btn" onClick={() => history.action === "PUSH" || history.action === "REPLACE" || history.action === "POP" ? history.goBack() : history.push('/home')}><i className="far fa-chevron-left"></i></div> }
                </div>
                <div className={`nav-item nav-title ${mainTitle === "首頁" ? 'nav-logo' : ''}`}>
                    { mainTitle === "首頁" ? (<></>
                        // <img src={require('../../images/logo/logo_white2.png')} alt="logo" />
                    ) : (
                        mainTitle ? mainTitle : subTitle
                    )}
                </div>
                <div className="nav-item nav-right">
                </div>
            </nav>
        </header>
    );
    
}