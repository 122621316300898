import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import * as action from '../../store/actions';
import AvatarImg from "../components/AvatarImg";
import ConfirmBox from "../../common/ConfirmBox";
import { logout, getBid, getOrgChart, getTravelPoint } from '../../services/authService';
import Skeleton from '@material-ui/lab/Skeleton';
import moment from 'moment/moment.js';
import { useQuery } from '@tanstack/react-query';

export default function Page(props) {
    const { isAuthenticated, user, setSnack, setLoader } = props;
    // Query: 合會紀錄
    const { data: bidData } = useQuery({ queryKey: ['bid'], queryFn: getBid });
    // Query: 組織圖
    const { data: orgChartData } = useQuery({ queryKey: ['orgChart'], queryFn: getOrgChart });
    // Query: 觀光點數
    const { data: travelPointData } = useQuery({ queryKey: ['travelPoint'], queryFn: getTravelPoint });

    return (
        <div className="dashboard-page">
            <div className="main-header">
                <div className="logo-img"></div>
            </div>
            <div className="bg-white">
                <div className="personal-info">
                    <div className="d-flex align-items-center w-100">
                        <div className="text-center">
                            <AvatarImg
                                className="m-auto"
                                name="avatar"
                                width="80"
                                height="80"
                                border="1px solid rgba(0,0,0,0.1)"
                                image={user.avatar}
                            />
                        </div>
                        <div className="mgl-3 flex-fill" style={{width: "1px"}}>
                            <div className="name">
                                {isAuthenticated ? user.name ? user.name : <Skeleton animation="wave" height={25} width={100} style={{ borderRadius: 25, marginBottom: 5 }}/> : "訪客"}
                            </div>
                            {isAuthenticated ? (<>
                                <div className="font-size-xs text-secondary text-ellipsis">{user.phone ? user.phone ? user.phone : <Skeleton animation="wave" height={15} width={150} style={{ borderRadius: 15, marginBottom: 5 }}/> : user.email ? user.email : <Skeleton animation="wave" height={15} width={150} style={{ borderRadius: 15, marginBottom: 5 }}/>}</div>
                                {/* <div>
                                    <div className={`font-size-ss text-white badge ${user.role === "一般會員" ? "bg-secondary" : "bg-primary"}`}>{user.role}</div>
                                    <div className={`font-size-ss text-white badge ${user.status === 0 ? "bg-danger" : "bg-success"} mgl-1`}>{user.status === 0 ? "未認證會員" : "已認證會員"}</div>
                                </div> */}
                            </>) : (<>
                                <Link to="/login" className="font-size-md text-secondary mgt-2">
                                    立即登入<i className="fal fa-angle-right mgl-1"></i>
                                </Link>
                            </>)}
                        </div>  
                    </div>
                </div>
                <div className='d-flex justify-content-center'>
                    <div className="pd-3 text-center">
                        <a href={bidData?.url} className="bid-record-link" target="_blank" rel="noreferrer">
                            <div className="bid-icon"><i className="common-icon bidRecord"></i></div>
                            <div className="font-weight-bold">合會紀錄查詢</div>
                        </a>
                        <div className="font-size-xs">最後更新時間：{!bidData?.url||bidData?.url===null||bidData?.url==="" ? '無紀錄' : bidData.updated_at && moment(bidData.updated_at).format('YYYY-MM-DD') }</div>
                    </div>

                    <div className="pd-3 text-center">
                        <a href={travelPointData?.url} className="bid-record-link" target="_blank" rel="noreferrer">
                            <div className="bid-icon"><i className="common-icon bidRecord"></i></div>
                            <div className="font-weight-bold">觀光點數查詢</div>
                        </a>
                        <div className="font-size-xs">最後更新時間：{!travelPointData?.url||travelPointData?.url===null||travelPointData?.url==="" ? '無紀錄' : travelPointData.updated_at && moment(travelPointData.updated_at).format('YYYY-MM-DD') }</div>
                    </div>

                    <div className="pd-3 text-center">
                        <a href={orgChartData?.url} className="bid-record-link" target="_blank" rel="noreferrer">
                            <div className="bid-icon"><i className="common-icon bidRecord"></i></div>
                            <div className="font-weight-bold">組織圖查詢</div>
                        </a>
                        <div className="font-size-xs">最後更新時間：{!orgChartData?.url||orgChartData?.url===null||orgChartData?.url==="" ? '無紀錄' : orgChartData.updated_at && moment(orgChartData.updated_at).format('YYYY-MM-DD') }</div>
                    </div>

                </div>
                {/* <div className="dashboard-wrapper">
                    <div className="pdx-3">
                        <Link to="/dashboard/upgrade" className="alert alert-warning font-size-xs pd-2 d-flex justify-content-between align-items-center mgb-2" role="alert">
                            <div><i className="fas fa-gem mgr-1"></i>認證為有效會員，享受每月業務制度分紅權益。</div>
                            <i className="fal fa-angle-right mgl-2"></i>
                        </Link>
                    </div>
                    <ul className="dashboard-box"> 
                        <li className="dashboard-item">
                            <Link to="/dashboard/wallet">
                                <i className="common-icon wallet icon-lg"></i>
                                <div className="label">我的錢包</div>
                            </Link>
                        </li>
                        <li className="dashboard-item">
                            <Link to="/dashboard/transfer">
                                <i className="common-icon transfer icon-lg"></i>
                                <div className="label">獎金轉點</div>
                            </Link>
                        </li>
                        <li className="dashboard-item">
                            <Link to="/dashboard/friends">
                                <i className="common-icon friends icon-lg"></i>
                                <div className="label">好友</div>
                            </Link>
                        </li>
                        <li className="dashboard-item">
                            <Link to="/dashboard/invite">
                                <i className="common-icon invite icon-lg"></i>
                                <div className="label">邀請好友</div>
                            </Link>
                        </li>
                    </ul>
                </div> */}
                <MenuList user={user} isAuthenticated={isAuthenticated}/>
                { isAuthenticated && <LogoutBtn dispatch={props.dispatch} setSnack={setSnack} setLoader={setLoader}/> }
            </div>
        </div>
    )
}

const AuthStatus = (status) => {
    switch (status) {
        case -1: return (<span className="font-size-ss text-white badge bg-danger">審核失敗</span>);
        case 0: return (<span className="text-secondary">尚未認證</span>);
        case 1: return (<span className="font-size-ss text-white badge bg-warning">審核中</span>);
        case 2: return (<span className="font-size-ss text-white badge bg-success">認證成功</span>);
        default: return;
    }
}

const MenuList = ({user,isAuthenticated}) => {

    let menu = [
        {
            title: '帳號設定',
            children: [
                {
                    link: "/dashboard/personal",
                    icon: "fal fa-pen fa-lg",
                    title: "修改個人資料",
                    auth: true,
                },
                {
                    link: "/dashboard/password",
                    icon: "fal fa-lock fa-lg",
                    title: "修改密碼",
                    auth: true,
                },
                {
                    link: "/dashboard/identity",
                    icon: "fal fa-id-card fa-lg",
                    title: "身份驗證",
                    auth: true,
                    desc: AuthStatus(user.identity_verified),
                },
                {
                    link: "/dashboard/bank",
                    icon: "fal fa-money-check-alt fa-lg",
                    title: "銀行帳戶",
                    auth: true,
                    desc: AuthStatus(user.bank_verified),
                },
            ],
        },
    ];

    if(!isAuthenticated) {
        let result = [...menu];
        result = result.map(el => {
            return {...el, children: el.children.filter(item => !item.auth)}
        })
        menu = result;
    }


    const ShowItem = ({item}) => {
        const Item = ({children, item}) => {
            return (
                <li className="dashboard-item">
                    { item.link && <Link to={item.link} className="dashboard-link">{ children }</Link> }
                    { item.onclick && <div onClick={item.onclick} className="dashboard-link">{ children }</div> }
                    { item.a && <a href={item.a} className="dashboard-link">{ children }</a> }
                </li>
            )
        }
    
        return (
            <Item item={item}>
                <div className="d-flex align-items-center">
                    <div className="icon"><i className={item.icon}></i></div>
                    <div className="title">{item.title}</div>
                </div>
                {item.desc}
            </Item>
        )
    }
    

    return (<>
        { menu.map((list, mainIndex) => {
            return (
                list.children.length > 0 &&
                <ul className="dashboard-list" key={mainIndex}>
                    <h5 className="pdt-3 pdx-3">{list.title}</h5>
                    { list.children.map((item, index) => <ShowItem key={index} mainIndex={mainIndex} index={index} item={item} />)}
                </ul>
            )
        })}
    </>)
}

const LogoutBtn = (props) => {
    const { setSnack, setLoader } = props;
    const [ confirmBoxToggle, setConfirmBoxToggle] = useState(false);

    const handleLogout = value => {
        setConfirmBoxToggle(value.open);
        if(value.confirm) {
            setLoader(true);
            logout().then(res => {
                setLoader(false);
                props.dispatch(action.authLogout());
                setSnack({open: true, text: "登出成功"});
            }).catch(err => {
                setLoader(false);
            })
        }
    }

    return (
        <div className="pdx-3 pdy-6">
            <button className="submit-btn logout" onClick={() => setConfirmBoxToggle(true)}>登出</button>
            <ConfirmBox
                open={confirmBoxToggle}
                confirmText="確定登出嗎?"
                callback={value => handleLogout(value)}
                cancel={{show: true, type: "button", text: "取消"}}
                confirm={{show: true, type: "submit", text: "確定"}}
            />
        </div>
    )
}