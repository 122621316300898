import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import HomeNews from './components/HomeNews';
import Banner from './components/Banner';
import { Sparklines, SparklinesLine, SparklinesSpots } from 'react-sparklines';

export default function Page(props) {
    const { isAuthenticated } = props;

    const bannerData = [
        {
            created_at: "2021-02-04 15:56:41",
            id: "1",
            image: "https://via.placeholder.com/750x350",
            name: "banner1",
        },
        {
            created_at: "2021-02-04 16:47:53",
            id: "2",
            image: "https://via.placeholder.com/750x350",
            name: "banner2",
        },
        {
            created_at: "2021-02-04 16:48:04",
            id: "3",
            image: "https://via.placeholder.com/750x350",
            name: "banner3",
        },
        {
            created_at: "2021-02-04 16:48:15",
            id: "4",
            image: "https://via.placeholder.com/750x350",
            name: "banner4",
        },
    ]

    const newsData = [
        {
            id: "1",
            title: "重要通知 ： SX星鏈量化用戶切記不要槓桿太高1",
        },
        {
            id: "2",
            title: "重要通知 ： SX星鏈量化用戶切記不要槓桿太高2",
        },
        {
            id: "3",
            title: "重要通知 ： SX星鏈量化用戶切記不要槓桿太高3",
        },
        {
            id: "4",
            title: "重要通知 ： SX星鏈量化用戶切記不要槓桿太高4",
        },
    ]

    const marketData = [
        {
            id: "1",
            name: "BTC",
            image: "btc",
            amount1: "57997.47",
            amount2: "-3.14",
        },
        {
            id: "2",
            name: "ETH",
            image: "eth",
            amount1: "4121",
            amount2: "4.21",
        },
        {
            id: "3",
            name: "DOGE",
            image: "doge",
            amount1: "0.473206",
            amount2: "0",
        },
        {
            id: "4",
            name: "Aave",
            image: "aave",
            amount1: "2400.45",
            amount2: "-9.62",
        },
        {
            id: "5",
            name: "DOT",
            image: "dot",
            amount1: "245.51",
            amount2: "-12.85",
        },
        {
            id: "6",
            name: "CRV",
            image: "crv",
            amount1: "18.21",
            amount2: "-12.85",
        },
        {
            id: "7",
            name: "UNI",
            image: "uni",
            amount1: "240.17",
            amount2: "-11.57",
        },
        {
            id: "8",
            name: "LINK",
            image: "link",
            amount1: "380.17",
            amount2: "-11.57",
        },
        {
            id: "9",
            name: "ATOM",
            image: "atom",
            amount1: "174.52",
            amount2: "-15.03",
        },
        {
            id: "10",
            name: "LTC",
            image: "ltc",
            amount1: "2308.41",
            amount2: "-8.42",
        },
        {
            id: "11",
            name: "BCH",
            image: "bch",
            amount1: "8201.65",
            amount2: "-14.42",
        },
    ]

    return (
        <div className="home-page">
            <div className="main-header">
                <div className="d-flex align-items-center">
                    <i className="common-icon home"></i>
                    <div className="title">首頁</div>
                </div>
                <Link to="/notification"><i className="fas fa-bell notification active"></i></Link>
            </div>
            <div className="bg-white">
                <Banner data={bannerData} />
                <HomeNews data={newsData} />
            </div>
            {
                isAuthenticated &&
                <div className="bg-white mgt-3">
                    <h5 className="font-weight-bold-xl pdt-3 pdx-3"><FormattedMessage id="app.tools" /></h5>
                    <ul className="tools-list">
                        <li className="tools-item">
                            <Link to="/dashboard/api-manage">
                                <i className="common-icon wallet icon-lg"></i>
                                <div className="label">我的錢包</div>
                            </Link>
                        </li>
                        <li className="tools-item">
                            <Link to="/dashboard/team">
                                <i className="common-icon transfer icon-lg"></i>
                                <div className="label">獎金轉點</div>
                            </Link>
                        </li>
                        <li className="tools-item">
                            <Link to="/dashboard/friends">
                                <i className="common-icon friends icon-lg"></i>
                                <div className="label">好友</div>
                            </Link>
                        </li>
                        <li className="tools-item">
                            <Link to="/dashboard/invite">
                                <i className="common-icon invite icon-lg"></i>
                                <div className="label"><FormattedMessage id="app.invite" /></div>
                            </Link>
                        </li>
                    </ul>
                </div>
            }
            <div className="bg-white mgt-3">
                <h5 className="font-weight-bold-xl pdt-3 pdx-3">標會預告</h5>
                {/* <div className="table-responsive">
                    <table className="table market-table">
                        <thead>
                            <tr>
                                <th scope="col">交易對</th>
                                <th scope="col">漲跌趨勢</th>
                                <th scope="col">24h漲跌%</th>
                            </tr> 
                        </thead>
                        <tbody>
                            { marketData.map((item, key) => <MarketItem item={item} key={key} />) }
                        </tbody>
                    </table>
                </div> */}
            </div>
        </div>
    )
}

const MarketItem = (props) => {
    const { item } = props;

    function boxMullerRandom () {
        let phase = false,
            x1, x2, w, z;
    
        return (function() {
    
            if (phase = !phase) {
                do {
                    x1 = 2.0 * Math.random() - 1.0;
                    x2 = 2.0 * Math.random() - 1.0;
                    w = x1 * x1 + x2 * x2;
                } while (w >= 1.0);
    
                w = Math.sqrt((-2.0 * Math.log(w)) / w);
                return x1 * w;
            } else {
                return x2 * w;
            }
        })();
    }
    
    function randomData(n = 30) {
        return Array.apply(0, Array(n)).map(boxMullerRandom);
    }
    
    const sampleData = randomData(24);

    return (
        <tr>
            <td>
                <div className="d-flex">
                    <i className={`common-icon ${item.image} mgr-2`} style={{width: "25px"}}></i>
                    <div>
                        <div><span className="font-weight-bold-xl font-size-sm" style={{fontWeight: "500"}}>{item.name}</span><span className="font-size-ss text-secondary"> / USDT</span></div>
                        <div className="font-weight-bold-xl font-size-ss" style={{fontWeight: "500"}}>${item.amount1}</div>
                    </div>
                </div>
            </td>
            <td style={{padding: "12px 0"}}>
                <Sparklines data={sampleData}>
                    <SparklinesLine style={{ strokeWidth: 3 }} color={parseFloat(item.amount2) != 0 ? (parseFloat(item.amount2) < 0 ? "#e63946" : "#11999E") : "#969696"} />
                </Sparklines>
            </td>
            <td className="text-right">
                 <div className={`opened ${parseFloat(item.amount2) != 0 && (parseFloat(item.amount2) < 0 ? "lower" : "higher")}`}>{parseFloat(item.amount2)} %</div>
             </td>
        </tr>
    )
}