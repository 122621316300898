import React, { useState } from 'react';

export default function Page() {
    const [ teamType, setTeamType ] = useState("1");

    const teamData = [
        {
            id: "1",
            name: "name1",
            all_team: "10",
            level1: "5",
            income: "1,041.1741",
            activation: "354",
        },
        {
            id: "2",
            name: "name2",
            all_team: "12",
            level1: "7",
            income: "14.7460",
            activation: "",
        },
    ]

    return (
        <div className="team-page">
            <div className="bg-white pd-3 mgb-3">
                <div>
                    <h5 className="font-weight-bold-xl mgb-0">直接好友</h5>
                    <div className="d-flex">
                        <div className="pdy-3" style={{flex: "1"}}>
                            <div>4 / 6</div>
                            <div className="font-size-xs text-secondary mgt-1">人數 (有效)</div>
                        </div>
                        <div className="pdy-3"  style={{flex: "1"}}>
                            <div>2.1545</div>
                            <div className="font-size-xs text-secondary mgt-1">今日分潤</div>
                        </div>
                        <div className="pdy-3"  style={{flex: "1"}}>
                            <div>14.2141</div>
                            <div className="font-size-xs text-secondary mgt-1">總分潤</div>
                        </div>
                    </div>
                </div>
                <div>
                    <h5 className="font-weight-bold-xl mgb-0">社區好友</h5>
                    <div className="d-flex">
                        <div className="pdy-3" style={{flex: "1"}}>
                            <div>4 / 6</div>
                            <div className="font-size-xs text-secondary mgt-1">人數 (有效)</div>
                        </div>
                        <div className="pdy-3"  style={{flex: "1"}}>
                            <div>2.1545</div>
                            <div className="font-size-xs text-secondary mgt-1">今日分潤</div>
                        </div>
                        <div className="pdy-3"  style={{flex: "1"}}>
                            <div>14.2141</div>
                            <div className="font-size-xs text-secondary mgt-1">總分潤</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-white">
                <h5 className="font-weight-bold-xl pdt-3 pdx-3">好友列表</h5>
                <div className="pdx-3 mgb-1">
                    <button type="button" className={`team-btn ${teamType === "1" ? "active" : ""}`} onClick={() => setTeamType("1")}>所有 (5)</button>
                    <button type="button" className={`team-btn ${teamType === "2" ? "active" : ""}`} onClick={() => setTeamType("2")}>有效 (4)</button>
                    <button type="button" className={`team-btn ${teamType === "3" ? "active" : ""}`} onClick={() => setTeamType("3")}>未激活 (1)</button>
                </div>
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">名稱</th>
                                <th scope="col">有效社區</th>
                                <th scope="col">有效直推</th>
                                <th scope="col">社區總收益</th>
                                <th scope="col">激活</th>
                            </tr>
                        </thead>
                        <tbody>
                            { teamData.map((item, key) => <TeamItem item={item} key={key} />) }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

const TeamItem = (props) => {
    const { item } = props;

    return (
        <tr>
            <td>{item.name}</td>
            <td>{item.all_team} 人</td>
            <td>{item.level1} 人</td>
            <td>{item.income}</td>
            <td><div className={`team-activation ${item.activation !== "" ? "active" : ""}`}>{item.activation !== "" ? `${item.activation}天` : "未激活"}</div></td>
        </tr>
    )
}